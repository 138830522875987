@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?13yask');
    src:    url('../fonts/icomoon.eot?13yask#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?13yask') format('truetype'),
        url('../fonts/icomoon.woff?13yask') format('woff'),
        url('../fonts/icomoon.svg?13yask#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-uniF1052:before {
    content: "\f2a4";
}
.icon-uniF1002:before {
    content: "\f2a5";
}
.icon-uniF1032:before {
    content: "\f2a6";
}
.icon-uniF1022:before {
    content: "\f2a7";
}
.icon-uniF1012:before {
    content: "\f2a8";
}
.icon-uniF1042:before {
    content: "\f2a9";
}
.icon-uniF101:before {
    content: "\f10f";
}
.icon-uniF11F:before {
    content: "\f11f";
}
.icon-uniF10B:before {
    content: "\f116";
}
.icon-uniF124:before {
    content: "\f12f";
}
.icon-uniF119:before {
    content: "\f13f";
}
.icon-uniF109:before {
    content: "\f117";
}
.icon-uniF108:before {
    content: "\f14f";
}
.icon-uniF103:before {
    content: "\f15f";
}
.icon-uniF10A:before {
    content: "\f16f";
}
.icon-uniF122:before {
    content: "\f17f";
}
.icon-uniF104:before {
    content: "\f18f";
}
.icon-uniF11B:before {
    content: "\f19f";
}
.icon-uniF129:before {
    content: "\f1af";
}
.icon-uniF128:before {
    content: "\f1bf";
}
.icon-uniF127:before {
    content: "\f1cf";
}
.icon-uniF11D:before {
    content: "\f1df";
}
.icon-uniF107:before {
    content: "\f1ef";
}
.icon-uniF117:before {
    content: "\f1ff";
}
.icon-uniF123:before {
    content: "\f20f";
}
.icon-uniF106:before {
    content: "\f21f";
}
.icon-uniF118:before {
    content: "\f220";
}
.icon-uniF115:before {
    content: "\f22e";
}
.icon-uniF105:before {
    content: "\f22f";
}
.icon-uniF120:before {
    content: "\f23f";
}
.icon-uniF126:before {
    content: "\f24f";
}
.icon-uniF116:before {
    content: "\f25f";
}
.icon-uniF11A:before {
    content: "\f26f";
}
.icon-uniF121:before {
    content: "\f27f";
}
.icon-uniF125:before {
    content: "\f28f";
}
.icon-uniF11C:before {
    content: "\f296";
}
.icon-uniF110:before {
    content: "\f297";
}
.icon-uniF10E:before {
    content: "\f298";
}
.icon-uniF102:before {
    content: "\f299";
}
.icon-uniF10F:before {
    content: "\f29a";
}
.icon-uniF111:before {
    content: "\f29b";
}
.icon-uniF10C:before {
    content: "\f29c";
}
.icon-uniF113:before {
    content: "\f29d";
}
.icon-uniF112:before {
    content: "\f29e";
}
.icon-uniF114:before {
    content: "\f29f";
}
.icon-uniF12A:before {
    content: "\f2a0";
}
.icon-uniF10D:before {
    content: "\f2a1";
}
.icon-uniF100:before {
    content: "\f2a2";
}
.icon-uniF11E:before {
    content: "\f2a3";
}
.icon-mobile3:before {
    content: "\e006";
}
.icon-laptop4:before {
    content: "\e007";
}
.icon-desktop2:before {
    content: "\e008";
}
.icon-tablet4:before {
    content: "\e009";
}
.icon-phone4:before {
    content: "\e00a";
}
.icon-document:before {
    content: "\e00b";
}
.icon-documents:before {
    content: "\e00c";
}
.icon-search4:before {
    content: "\e00d";
}
.icon-clipboard3:before {
    content: "\e00e";
}
.icon-newspaper2:before {
    content: "\e00f";
}
.icon-notebook:before {
    content: "\e010";
}
.icon-book-open:before {
    content: "\e011";
}
.icon-browser:before {
    content: "\e012";
}
.icon-calendar3:before {
    content: "\e013";
}
.icon-presentation:before {
    content: "\e014";
}
.icon-picture:before {
    content: "\e015";
}
.icon-pictures:before {
    content: "\e016";
}
.icon-video:before {
    content: "\e017";
}
.icon-camera4:before {
    content: "\e018";
}
.icon-printer2:before {
    content: "\e019";
}
.icon-toolbox:before {
    content: "\e01a";
}
.icon-briefcase3:before {
    content: "\e01b";
}
.icon-wallet:before {
    content: "\e01c";
}
.icon-gift3:before {
    content: "\e01d";
}
.icon-bargraph:before {
    content: "\e01e";
}
.icon-grid:before {
    content: "\e01f";
}
.icon-expand2:before {
    content: "\e020";
}
.icon-focus:before {
    content: "\e021";
}
.icon-edit3:before {
    content: "\e022";
}
.icon-adjustments:before {
    content: "\e023";
}
.icon-ribbon:before {
    content: "\e024";
}
.icon-hourglass2:before {
    content: "\e025";
}
.icon-lock4:before {
    content: "\e026";
}
.icon-megaphone:before {
    content: "\e027";
}
.icon-shield3:before {
    content: "\e028";
}
.icon-trophy3:before {
    content: "\e029";
}
.icon-flag4:before {
    content: "\e02a";
}
.icon-map4:before {
    content: "\e02b";
}
.icon-puzzle:before {
    content: "\e02c";
}
.icon-basket:before {
    content: "\e02d";
}
.icon-envelope2:before {
    content: "\e02e";
}
.icon-streetsign:before {
    content: "\e02f";
}
.icon-telescope:before {
    content: "\e030";
}
.icon-gears2:before {
    content: "\e031";
}
.icon-key3:before {
    content: "\e032";
}
.icon-paperclip2:before {
    content: "\e033";
}
.icon-attachment3:before {
    content: "\e034";
}
.icon-pricetags:before {
    content: "\e035";
}
.icon-lightbulb:before {
    content: "\e036";
}
.icon-layers2:before {
    content: "\e037";
}
.icon-pencil3:before {
    content: "\e038";
}
.icon-tools:before {
    content: "\e039";
}
.icon-tools-2:before {
    content: "\e03a";
}
.icon-scissors3:before {
    content: "\e03b";
}
.icon-paintbrush:before {
    content: "\e03c";
}
.icon-magnifying-glass:before {
    content: "\e03d";
}
.icon-circle-compass:before {
    content: "\e03e";
}
.icon-linegraph:before {
    content: "\e03f";
}
.icon-mic3:before {
    content: "\e040";
}
.icon-strategy:before {
    content: "\e041";
}
.icon-beaker:before {
    content: "\e042";
}
.icon-caution:before {
    content: "\e043";
}
.icon-recycle2:before {
    content: "\e044";
}
.icon-anchor2:before {
    content: "\e045";
}
.icon-profile-male:before {
    content: "\e046";
}
.icon-profile-female:before {
    content: "\e047";
}
.icon-bike:before {
    content: "\e048";
}
.icon-wine:before {
    content: "\e049";
}
.icon-hotairballoon:before {
    content: "\e04a";
}
.icon-globe2:before {
    content: "\e04b";
}
.icon-genius:before {
    content: "\e04c";
}
.icon-map-pin2:before {
    content: "\e04d";
}
.icon-dial:before {
    content: "\e04e";
}
.icon-chat2:before {
    content: "\e04f";
}
.icon-heart3:before {
    content: "\e050";
}
.icon-cloud4:before {
    content: "\e051";
}
.icon-upload4:before {
    content: "\e052";
}
.icon-download4:before {
    content: "\e053";
}
.icon-target2:before {
    content: "\e054";
}
.icon-hazardous:before {
    content: "\e055";
}
.icon-piechart:before {
    content: "\e056";
}
.icon-speedometer:before {
    content: "\e057";
}
.icon-global:before {
    content: "\e058";
}
.icon-compass3:before {
    content: "\e059";
}
.icon-lifesaver:before {
    content: "\e05a";
}
.icon-clock3:before {
    content: "\e05b";
}
.icon-aperture:before {
    content: "\e05c";
}
.icon-quote:before {
    content: "\e05d";
}
.icon-scope:before {
    content: "\e05e";
}
.icon-alarmclock:before {
    content: "\e05f";
}
.icon-refresh3:before {
    content: "\e060";
}
.icon-happy3:before {
    content: "\e061";
}
.icon-sad3:before {
    content: "\e062";
}
.icon-facebook3:before {
    content: "\e063";
}
.icon-twitter3:before {
    content: "\e064";
}
.icon-googleplus:before {
    content: "\e065";
}
.icon-rss3:before {
    content: "\e066";
}
.icon-tumblr3:before {
    content: "\e067";
}
.icon-linkedin3:before {
    content: "\e068";
}
.icon-dribbble3:before {
    content: "\e069";
}
.icon-error:before {
    content: "\e06a";
}
.icon-error_outline:before {
    content: "\e06b";
}
.icon-warning:before {
    content: "\e06c";
}
.icon-add_alert:before {
    content: "\e06d";
}
.icon-album:before {
    content: "\e06e";
}
.icon-av_timer:before {
    content: "\e06f";
}
.icon-closed_caption:before {
    content: "\e070";
}
.icon-equalizer:before {
    content: "\e071";
}
.icon-explicit:before {
    content: "\e072";
}
.icon-fast_forward:before {
    content: "\e073";
}
.icon-fast_rewind:before {
    content: "\e074";
}
.icon-games:before {
    content: "\e075";
}
.icon-hearing:before {
    content: "\e076";
}
.icon-high_quality:before {
    content: "\e077";
}
.icon-loop:before {
    content: "\e078";
}
.icon-mic:before {
    content: "\e079";
}
.icon-mic_none:before {
    content: "\e07a";
}
.icon-mic_off:before {
    content: "\e07b";
}
.icon-movie:before {
    content: "\e07c";
}
.icon-library_add:before {
    content: "\e07d";
}
.icon-library_books:before {
    content: "\e07e";
}
.icon-library_music:before {
    content: "\e07f";
}
.icon-new_releases:before {
    content: "\e080";
}
.icon-not_interested:before {
    content: "\e081";
}
.icon-pause:before {
    content: "\e082";
}
.icon-pause_circle_filled:before {
    content: "\e083";
}
.icon-pause_circle_outline:before {
    content: "\e084";
}
.icon-play_arrow:before {
    content: "\e085";
}
.icon-play_circle_filled:before {
    content: "\e086";
}
.icon-play_circle_outline:before {
    content: "\e087";
}
.icon-playlist_add:before {
    content: "\e088";
}
.icon-queue:before {
    content: "\e089";
}
.icon-queue_music:before {
    content: "\e08a";
}
.icon-radio:before {
    content: "\e08b";
}
.icon-recent_actors:before {
    content: "\e08c";
}
.icon-repeat:before {
    content: "\e08d";
}
.icon-repeat_one:before {
    content: "\e08e";
}
.icon-replay:before {
    content: "\e08f";
}
.icon-shuffle:before {
    content: "\e090";
}
.icon-skip_next:before {
    content: "\e091";
}
.icon-skip_previous:before {
    content: "\e092";
}
.icon-snooze:before {
    content: "\e093";
}
.icon-stop:before {
    content: "\e094";
}
.icon-subtitles:before {
    content: "\e095";
}
.icon-surround_sound:before {
    content: "\e096";
}
.icon-video_collection:before {
    content: "\e097";
}
.icon-videocam:before {
    content: "\e098";
}
.icon-videocam_off:before {
    content: "\e099";
}
.icon-volume_down:before {
    content: "\e09a";
}
.icon-volume_mute:before {
    content: "\e09b";
}
.icon-volume_off:before {
    content: "\e09c";
}
.icon-volume_up:before {
    content: "\e09d";
}
.icon-web:before {
    content: "\e09e";
}
.icon-hd:before {
    content: "\e09f";
}
.icon-sort_by_alpha:before {
    content: "\e0a0";
}
.icon-airplay:before {
    content: "\e0a1";
}
.icon-forward_10:before {
    content: "\e0a2";
}
.icon-forward_30:before {
    content: "\e0a3";
}
.icon-forward_5:before {
    content: "\e0a4";
}
.icon-replay_10:before {
    content: "\e0a5";
}
.icon-replay_30:before {
    content: "\e0a6";
}
.icon-replay_5:before {
    content: "\e0a7";
}
.icon-add_to_queue:before {
    content: "\e0a8";
}
.icon-fiber_dvr:before {
    content: "\e0a9";
}
.icon-fiber_new:before {
    content: "\e0aa";
}
.icon-playlist_play:before {
    content: "\e0ab";
}
.icon-art_track:before {
    content: "\e0ac";
}
.icon-fiber_manual_record:before {
    content: "\e0ad";
}
.icon-fiber_smart_record:before {
    content: "\e0ae";
}
.icon-music_video:before {
    content: "\e0af";
}
.icon-subscriptions:before {
    content: "\e0b0";
}
.icon-playlist_add_check:before {
    content: "\e0b1";
}
.icon-queue_play_next:before {
    content: "\e0b2";
}
.icon-remove_from_queue:before {
    content: "\e0b3";
}
.icon-slow_motion_video:before {
    content: "\e0b4";
}
.icon-web_asset:before {
    content: "\e0b5";
}
.icon-fiber_pin:before {
    content: "\e0b6";
}
.icon-business:before {
    content: "\e0b7";
}
.icon-call:before {
    content: "\e0b8";
}
.icon-call_end:before {
    content: "\e0b9";
}
.icon-call_made:before {
    content: "\e0ba";
}
.icon-call_merge:before {
    content: "\e0bb";
}
.icon-call_missed:before {
    content: "\e0bc";
}
.icon-call_received:before {
    content: "\e0bd";
}
.icon-call_split:before {
    content: "\e0be";
}
.icon-chat:before {
    content: "\e0bf";
}
.icon-clear_all:before {
    content: "\e0c0";
}
.icon-comment:before {
    content: "\e0c1";
}
.icon-contacts:before {
    content: "\e0c2";
}
.icon-dialer_sip:before {
    content: "\e0c3";
}
.icon-dialpad:before {
    content: "\e0c4";
}
.icon-email:before {
    content: "\e0c5";
}
.icon-forum:before {
    content: "\e0c6";
}
.icon-import_export:before {
    content: "\e0c7";
}
.icon-invert_colors_off:before {
    content: "\e0c8";
}
.icon-live_help:before {
    content: "\e0c9";
}
.icon-location_off:before {
    content: "\e0ca";
}
.icon-location_on:before {
    content: "\e0cb";
}
.icon-message:before {
    content: "\e0cc";
}
.icon-chat_bubble:before {
    content: "\e0cd";
}
.icon-chat_bubble_outline:before {
    content: "\e0ce";
}
.icon-no_sim:before {
    content: "\e0cf";
}
.icon-phone:before {
    content: "\e0d0";
}
.icon-portable_wifi_off:before {
    content: "\e0d1";
}
.icon-contact_phone:before {
    content: "\e0d2";
}
.icon-contact_mail:before {
    content: "\e0d3";
}
.icon-ring_volume:before {
    content: "\e0d4";
}
.icon-speaker_phone:before {
    content: "\e0d5";
}
.icon-stay_current_landscape:before {
    content: "\e0d6";
}
.icon-stay_current_portrait:before {
    content: "\e0d7";
}
.icon-stay_primary_landscape:before {
    content: "\e0d8";
}
.icon-stay_primary_portrait:before {
    content: "\e0d9";
}
.icon-swap_calls:before {
    content: "\e0da";
}
.icon-textsms:before {
    content: "\e0db";
}
.icon-voicemail:before {
    content: "\e0dc";
}
.icon-vpn_key:before {
    content: "\e0dd";
}
.icon-phonelink_erase:before {
    content: "\e0de";
}
.icon-phonelink_lock:before {
    content: "\e0df";
}
.icon-phonelink_ring:before {
    content: "\e0e0";
}
.icon-phonelink_setup:before {
    content: "\e0e1";
}
.icon-present_to_all:before {
    content: "\e0e2";
}
.icon-import_contacts:before {
    content: "\e0e3";
}
.icon-mail_outline:before {
    content: "\e0e4";
}
.icon-screen_share:before {
    content: "\e0e5";
}
.icon-stop_screen_share:before {
    content: "\e0e6";
}
.icon-call_missed_outgoing:before {
    content: "\e0e7";
}
.icon-add:before {
    content: "\e145";
}
.icon-add_box:before {
    content: "\e146";
}
.icon-add_circle:before {
    content: "\e147";
}
.icon-add_circle_outline:before {
    content: "\e148";
}
.icon-archive:before {
    content: "\e149";
}
.icon-backspace:before {
    content: "\e14a";
}
.icon-block:before {
    content: "\e14b";
}
.icon-clear:before {
    content: "\e14c";
}
.icon-content_copy:before {
    content: "\e14d";
}
.icon-content_cut:before {
    content: "\e14e";
}
.icon-content_paste:before {
    content: "\e14f";
}
.icon-create:before {
    content: "\e150";
}
.icon-drafts:before {
    content: "\e151";
}
.icon-filter_list:before {
    content: "\e152";
}
.icon-flag:before {
    content: "\e153";
}
.icon-forward:before {
    content: "\e154";
}
.icon-gesture:before {
    content: "\e155";
}
.icon-inbox:before {
    content: "\e156";
}
.icon-link:before {
    content: "\e157";
}
.icon-mail:before {
    content: "\e158";
}
.icon-markunread:before {
    content: "\e159";
}
.icon-redo:before {
    content: "\e15a";
}
.icon-remove:before {
    content: "\e15b";
}
.icon-remove_circle:before {
    content: "\e15c";
}
.icon-remove_circle_outline:before {
    content: "\e15d";
}
.icon-reply:before {
    content: "\e15e";
}
.icon-reply_all:before {
    content: "\e15f";
}
.icon-report:before {
    content: "\e160";
}
.icon-save:before {
    content: "\e161";
}
.icon-select_all:before {
    content: "\e162";
}
.icon-send:before {
    content: "\e163";
}
.icon-sort:before {
    content: "\e164";
}
.icon-text_format:before {
    content: "\e165";
}
.icon-undo:before {
    content: "\e166";
}
.icon-font_download:before {
    content: "\e167";
}
.icon-move_to_inbox:before {
    content: "\e168";
}
.icon-unarchive:before {
    content: "\e169";
}
.icon-next_week:before {
    content: "\e16a";
}
.icon-weekend:before {
    content: "\e16b";
}
.icon-access_alarm:before {
    content: "\e190";
}
.icon-access_alarms:before {
    content: "\e191";
}
.icon-access_time:before {
    content: "\e192";
}
.icon-add_alarm:before {
    content: "\e193";
}
.icon-airplanemode_inactive:before {
    content: "\e194";
}
.icon-airplanemode_active:before {
    content: "\e195";
}
.icon-battery_alert:before {
    content: "\e19c";
}
.icon-battery_charging_full:before {
    content: "\e1a3";
}
.icon-battery_full:before {
    content: "\e1a4";
}
.icon-battery_std:before {
    content: "\e1a5";
}
.icon-battery_unknown:before {
    content: "\e1a6";
}
.icon-bluetooth:before {
    content: "\e1a7";
}
.icon-bluetooth_connected:before {
    content: "\e1a8";
}
.icon-bluetooth_disabled:before {
    content: "\e1a9";
}
.icon-bluetooth_searching:before {
    content: "\e1aa";
}
.icon-brightness_auto:before {
    content: "\e1ab";
}
.icon-brightness_high:before {
    content: "\e1ac";
}
.icon-brightness_low:before {
    content: "\e1ad";
}
.icon-brightness_medium:before {
    content: "\e1ae";
}
.icon-data_usage:before {
    content: "\e1af";
}
.icon-developer_mode:before {
    content: "\e1b0";
}
.icon-devices:before {
    content: "\e1b1";
}
.icon-dvr:before {
    content: "\e1b2";
}
.icon-gps_fixed:before {
    content: "\e1b3";
}
.icon-gps_not_fixed:before {
    content: "\e1b4";
}
.icon-gps_off:before {
    content: "\e1b5";
}
.icon-location_disabled:before {
    content: "\e1b6";
}
.icon-location_searching:before {
    content: "\e1b7";
}
.icon-graphic_eq:before {
    content: "\e1b8";
}
.icon-network_cell:before {
    content: "\e1b9";
}
.icon-network_wifi:before {
    content: "\e1ba";
}
.icon-nfc:before {
    content: "\e1bb";
}
.icon-now_wallpaper:before {
    content: "\e1bc";
}
.icon-now_widgets:before {
    content: "\e1bd";
}
.icon-screen_lock_landscape:before {
    content: "\e1be";
}
.icon-screen_lock_portrait:before {
    content: "\e1bf";
}
.icon-screen_lock_rotation:before {
    content: "\e1c0";
}
.icon-screen_rotation:before {
    content: "\e1c1";
}
.icon-sd_storage:before {
    content: "\e1c2";
}
.icon-settings_system_daydream:before {
    content: "\e1c3";
}
.icon-signal_cellular_4_bar:before {
    content: "\e1c8";
}
.icon-signal_cellular_connected_no_internet_4_bar:before {
    content: "\e1cd";
}
.icon-signal_cellular_no_sim:before {
    content: "\e1ce";
}
.icon-signal_cellular_null:before {
    content: "\e1cf";
}
.icon-signal_cellular_off:before {
    content: "\e1d0";
}
.icon-signal_wifi_4_bar:before {
    content: "\e1d8";
}
.icon-signal_wifi_4_bar_lock:before {
    content: "\e1d9";
}
.icon-signal_wifi_off:before {
    content: "\e1da";
}
.icon-storage:before {
    content: "\e1db";
}
.icon-usb:before {
    content: "\e1e0";
}
.icon-wifi_lock:before {
    content: "\e1e1";
}
.icon-wifi_tethering:before {
    content: "\e1e2";
}
.icon-attach_file:before {
    content: "\e226";
}
.icon-attach_money:before {
    content: "\e227";
}
.icon-border_all:before {
    content: "\e228";
}
.icon-border_bottom:before {
    content: "\e229";
}
.icon-border_clear:before {
    content: "\e22a";
}
.icon-border_color:before {
    content: "\e22b";
}
.icon-border_horizontal:before {
    content: "\e22c";
}
.icon-border_inner:before {
    content: "\e22d";
}
.icon-border_left:before {
    content: "\e22e";
}
.icon-border_outer:before {
    content: "\e22f";
}
.icon-border_right:before {
    content: "\e230";
}
.icon-border_style:before {
    content: "\e231";
}
.icon-border_top:before {
    content: "\e232";
}
.icon-border_vertical:before {
    content: "\e233";
}
.icon-format_align_center:before {
    content: "\e234";
}
.icon-format_align_justify:before {
    content: "\e235";
}
.icon-format_align_left:before {
    content: "\e236";
}
.icon-format_align_right:before {
    content: "\e237";
}
.icon-format_bold:before {
    content: "\e238";
}
.icon-format_clear:before {
    content: "\e239";
}
.icon-format_color_fill:before {
    content: "\e23a";
}
.icon-format_color_reset:before {
    content: "\e23b";
}
.icon-format_color_text:before {
    content: "\e23c";
}
.icon-format_indent_decrease:before {
    content: "\e23d";
}
.icon-format_indent_increase:before {
    content: "\e23e";
}
.icon-format_italic:before {
    content: "\e23f";
}
.icon-format_line_spacing:before {
    content: "\e240";
}
.icon-format_list_bulleted:before {
    content: "\e241";
}
.icon-format_list_numbered:before {
    content: "\e242";
}
.icon-format_paint:before {
    content: "\e243";
}
.icon-format_quote:before {
    content: "\e244";
}
.icon-format_size:before {
    content: "\e245";
}
.icon-format_strikethrough:before {
    content: "\e246";
}
.icon-format_textdirection_l_to_r:before {
    content: "\e247";
}
.icon-format_textdirection_r_to_l:before {
    content: "\e248";
}
.icon-format_underlined:before {
    content: "\e249";
}
.icon-functions:before {
    content: "\e24a";
}
.icon-insert_chart:before {
    content: "\e24b";
}
.icon-insert_comment:before {
    content: "\e24c";
}
.icon-insert_drive_file:before {
    content: "\e24d";
}
.icon-insert_emoticon:before {
    content: "\e24e";
}
.icon-insert_invitation:before {
    content: "\e24f";
}
.icon-insert_link:before {
    content: "\e250";
}
.icon-insert_photo:before {
    content: "\e251";
}
.icon-merge_type:before {
    content: "\e252";
}
.icon-mode_comment:before {
    content: "\e253";
}
.icon-mode_edit:before {
    content: "\e254";
}
.icon-publish:before {
    content: "\e255";
}
.icon-space_bar:before {
    content: "\e256";
}
.icon-strikethrough_s:before {
    content: "\e257";
}
.icon-vertical_align_bottom:before {
    content: "\e258";
}
.icon-vertical_align_center:before {
    content: "\e259";
}
.icon-vertical_align_top:before {
    content: "\e25a";
}
.icon-wrap_text:before {
    content: "\e25b";
}
.icon-money_off:before {
    content: "\e25c";
}
.icon-drag_handle:before {
    content: "\e25d";
}
.icon-format_shapes:before {
    content: "\e25e";
}
.icon-highlight:before {
    content: "\e25f";
}
.icon-linear_scale:before {
    content: "\e260";
}
.icon-short_text:before {
    content: "\e261";
}
.icon-text_fields:before {
    content: "\e262";
}
.icon-attachment:before {
    content: "\e2bc";
}
.icon-cloud:before {
    content: "\e2bd";
}
.icon-cloud_circle:before {
    content: "\e2be";
}
.icon-cloud_done:before {
    content: "\e2bf";
}
.icon-cloud_download:before {
    content: "\e2c0";
}
.icon-cloud_off:before {
    content: "\e2c1";
}
.icon-cloud_queue:before {
    content: "\e2c2";
}
.icon-cloud_upload:before {
    content: "\e2c3";
}
.icon-file_download:before {
    content: "\e2c4";
}
.icon-file_upload:before {
    content: "\e2c6";
}
.icon-folder:before {
    content: "\e2c7";
}
.icon-folder_open:before {
    content: "\e2c8";
}
.icon-folder_shared:before {
    content: "\e2c9";
}
.icon-create_new_folder:before {
    content: "\e2cc";
}
.icon-cast:before {
    content: "\e307";
}
.icon-cast_connected:before {
    content: "\e308";
}
.icon-computer:before {
    content: "\e30a";
}
.icon-desktop_mac:before {
    content: "\e30b";
}
.icon-desktop_windows:before {
    content: "\e30c";
}
.icon-developer_board:before {
    content: "\e30d";
}
.icon-dock:before {
    content: "\e30e";
}
.icon-gamepad:before {
    content: "\e30f";
}
.icon-headset:before {
    content: "\e310";
}
.icon-headset_mic:before {
    content: "\e311";
}
.icon-keyboard:before {
    content: "\e312";
}
.icon-keyboard_arrow_down:before {
    content: "\e313";
}
.icon-keyboard_arrow_left:before {
    content: "\e314";
}
.icon-keyboard_arrow_right:before {
    content: "\e315";
}
.icon-keyboard_arrow_up:before {
    content: "\e316";
}
.icon-keyboard_backspace:before {
    content: "\e317";
}
.icon-keyboard_capslock:before {
    content: "\e318";
}
.icon-keyboard_hide:before {
    content: "\e31a";
}
.icon-keyboard_return:before {
    content: "\e31b";
}
.icon-keyboard_tab:before {
    content: "\e31c";
}
.icon-keyboard_voice:before {
    content: "\e31d";
}
.icon-laptop:before {
    content: "\e31e";
}
.icon-laptop_chromebook:before {
    content: "\e31f";
}
.icon-laptop_mac:before {
    content: "\e320";
}
.icon-laptop_windows:before {
    content: "\e321";
}
.icon-memory:before {
    content: "\e322";
}
.icon-mouse:before {
    content: "\e323";
}
.icon-phone_android:before {
    content: "\e324";
}
.icon-phone_iphone:before {
    content: "\e325";
}
.icon-phonelink:before {
    content: "\e326";
}
.icon-phonelink_off:before {
    content: "\e327";
}
.icon-router:before {
    content: "\e328";
}
.icon-scanner:before {
    content: "\e329";
}
.icon-security:before {
    content: "\e32a";
}
.icon-sim_card:before {
    content: "\e32b";
}
.icon-smartphone:before {
    content: "\e32c";
}
.icon-speaker:before {
    content: "\e32d";
}
.icon-speaker_group:before {
    content: "\e32e";
}
.icon-tablet:before {
    content: "\e32f";
}
.icon-tablet_android:before {
    content: "\e330";
}
.icon-tablet_mac:before {
    content: "\e331";
}
.icon-toys:before {
    content: "\e332";
}
.icon-tv:before {
    content: "\e333";
}
.icon-watch:before {
    content: "\e334";
}
.icon-device_hub:before {
    content: "\e335";
}
.icon-power_input:before {
    content: "\e336";
}
.icon-devices_other:before {
    content: "\e337";
}
.icon-videogame_asset:before {
    content: "\e338";
}
.icon-add_to_photos:before {
    content: "\e39d";
}
.icon-adjust:before {
    content: "\e39e";
}
.icon-assistant:before {
    content: "\e39f";
}
.icon-assistant_photo:before {
    content: "\e3a0";
}
.icon-audiotrack:before {
    content: "\e3a1";
}
.icon-blur_circular:before {
    content: "\e3a2";
}
.icon-blur_linear:before {
    content: "\e3a3";
}
.icon-blur_off:before {
    content: "\e3a4";
}
.icon-blur_on:before {
    content: "\e3a5";
}
.icon-brightness_1:before {
    content: "\e3a6";
}
.icon-brightness_2:before {
    content: "\e3a7";
}
.icon-brightness_3:before {
    content: "\e3a8";
}
.icon-brightness_4:before {
    content: "\e3a9";
}
.icon-brightness_5:before {
    content: "\e3aa";
}
.icon-brightness_6:before {
    content: "\e3ab";
}
.icon-brightness_7:before {
    content: "\e3ac";
}
.icon-broken_image:before {
    content: "\e3ad";
}
.icon-brush:before {
    content: "\e3ae";
}
.icon-camera:before {
    content: "\e3af";
}
.icon-camera_alt:before {
    content: "\e3b0";
}
.icon-camera_front:before {
    content: "\e3b1";
}
.icon-camera_rear:before {
    content: "\e3b2";
}
.icon-camera_roll:before {
    content: "\e3b3";
}
.icon-center_focus_strong:before {
    content: "\e3b4";
}
.icon-center_focus_weak:before {
    content: "\e3b5";
}
.icon-collections:before {
    content: "\e3b6";
}
.icon-color_lens:before {
    content: "\e3b7";
}
.icon-colorize:before {
    content: "\e3b8";
}
.icon-compare:before {
    content: "\e3b9";
}
.icon-control_point:before {
    content: "\e3ba";
}
.icon-control_point_duplicate:before {
    content: "\e3bb";
}
.icon-crop_16_9:before {
    content: "\e3bc";
}
.icon-crop_3_2:before {
    content: "\e3bd";
}
.icon-crop:before {
    content: "\e3be";
}
.icon-crop_5_4:before {
    content: "\e3bf";
}
.icon-crop_7_5:before {
    content: "\e3c0";
}
.icon-crop_din:before {
    content: "\e3c1";
}
.icon-crop_free:before {
    content: "\e3c2";
}
.icon-crop_landscape:before {
    content: "\e3c3";
}
.icon-crop_original:before {
    content: "\e3c4";
}
.icon-crop_portrait:before {
    content: "\e3c5";
}
.icon-crop_square:before {
    content: "\e3c6";
}
.icon-dehaze:before {
    content: "\e3c7";
}
.icon-details:before {
    content: "\e3c8";
}
.icon-edit:before {
    content: "\e3c9";
}
.icon-exposure:before {
    content: "\e3ca";
}
.icon-exposure_minus_1:before {
    content: "\e3cb";
}
.icon-exposure_minus_2:before {
    content: "\e3cc";
}
.icon-exposure_plus_1:before {
    content: "\e3cd";
}
.icon-exposure_plus_2:before {
    content: "\e3ce";
}
.icon-exposure_zero:before {
    content: "\e3cf";
}
.icon-filter_1:before {
    content: "\e3d0";
}
.icon-filter_2:before {
    content: "\e3d1";
}
.icon-filter_3:before {
    content: "\e3d2";
}
.icon-filter:before {
    content: "\e3d3";
}
.icon-filter_4:before {
    content: "\e3d4";
}
.icon-filter_5:before {
    content: "\e3d5";
}
.icon-filter_6:before {
    content: "\e3d6";
}
.icon-filter_7:before {
    content: "\e3d7";
}
.icon-filter_8:before {
    content: "\e3d8";
}
.icon-filter_9:before {
    content: "\e3d9";
}
.icon-filter_9_plus:before {
    content: "\e3da";
}
.icon-filter_b_and_w:before {
    content: "\e3db";
}
.icon-filter_center_focus:before {
    content: "\e3dc";
}
.icon-filter_drama:before {
    content: "\e3dd";
}
.icon-filter_frames:before {
    content: "\e3de";
}
.icon-filter_hdr:before {
    content: "\e3df";
}
.icon-filter_none:before {
    content: "\e3e0";
}
.icon-filter_tilt_shift:before {
    content: "\e3e2";
}
.icon-filter_vintage:before {
    content: "\e3e3";
}
.icon-flare:before {
    content: "\e3e4";
}
.icon-flash_auto:before {
    content: "\e3e5";
}
.icon-flash_off:before {
    content: "\e3e6";
}
.icon-flash_on:before {
    content: "\e3e7";
}
.icon-flip:before {
    content: "\e3e8";
}
.icon-gradient:before {
    content: "\e3e9";
}
.icon-grain:before {
    content: "\e3ea";
}
.icon-grid_off:before {
    content: "\e3eb";
}
.icon-grid_on:before {
    content: "\e3ec";
}
.icon-hdr_off:before {
    content: "\e3ed";
}
.icon-hdr_on:before {
    content: "\e3ee";
}
.icon-hdr_strong:before {
    content: "\e3f1";
}
.icon-hdr_weak:before {
    content: "\e3f2";
}
.icon-healing:before {
    content: "\e3f3";
}
.icon-image:before {
    content: "\e3f4";
}
.icon-image_aspect_ratio:before {
    content: "\e3f5";
}
.icon-iso:before {
    content: "\e3f6";
}
.icon-landscape:before {
    content: "\e3f7";
}
.icon-leak_add:before {
    content: "\e3f8";
}
.icon-leak_remove:before {
    content: "\e3f9";
}
.icon-lens:before {
    content: "\e3fa";
}
.icon-looks_3:before {
    content: "\e3fb";
}
.icon-looks:before {
    content: "\e3fc";
}
.icon-looks_4:before {
    content: "\e3fd";
}
.icon-looks_5:before {
    content: "\e3fe";
}
.icon-looks_6:before {
    content: "\e3ff";
}
.icon-looks_one:before {
    content: "\e400";
}
.icon-looks_two:before {
    content: "\e401";
}
.icon-loupe:before {
    content: "\e402";
}
.icon-monochrome_photos:before {
    content: "\e403";
}
.icon-movie_creation:before {
    content: "\e404";
}
.icon-music_note:before {
    content: "\e405";
}
.icon-nature:before {
    content: "\e406";
}
.icon-nature_people:before {
    content: "\e407";
}
.icon-navigate_before:before {
    content: "\e408";
}
.icon-navigate_next:before {
    content: "\e409";
}
.icon-palette:before {
    content: "\e40a";
}
.icon-panorama:before {
    content: "\e40b";
}
.icon-panorama_fish_eye:before {
    content: "\e40c";
}
.icon-panorama_horizontal:before {
    content: "\e40d";
}
.icon-panorama_vertical:before {
    content: "\e40e";
}
.icon-panorama_wide_angle:before {
    content: "\e40f";
}
.icon-photo:before {
    content: "\e410";
}
.icon-photo_album:before {
    content: "\e411";
}
.icon-photo_camera:before {
    content: "\e412";
}
.icon-photo_library:before {
    content: "\e413";
}
.icon-picture_as_pdf:before {
    content: "\e415";
}
.icon-portrait:before {
    content: "\e416";
}
.icon-remove_red_eye:before {
    content: "\e417";
}
.icon-rotate_90_degrees_ccw:before {
    content: "\e418";
}
.icon-rotate_left:before {
    content: "\e419";
}
.icon-rotate_right:before {
    content: "\e41a";
}
.icon-slideshow:before {
    content: "\e41b";
}
.icon-straighten:before {
    content: "\e41c";
}
.icon-style:before {
    content: "\e41d";
}
.icon-switch_camera:before {
    content: "\e41e";
}
.icon-switch_video:before {
    content: "\e41f";
}
.icon-tag_faces:before {
    content: "\e420";
}
.icon-texture:before {
    content: "\e421";
}
.icon-timelapse:before {
    content: "\e422";
}
.icon-timer_10:before {
    content: "\e423";
}
.icon-timer_3:before {
    content: "\e424";
}
.icon-timer:before {
    content: "\e425";
}
.icon-timer_off:before {
    content: "\e426";
}
.icon-tonality:before {
    content: "\e427";
}
.icon-transform:before {
    content: "\e428";
}
.icon-tune:before {
    content: "\e429";
}
.icon-view_comfortable:before {
    content: "\e42a";
}
.icon-view_compact:before {
    content: "\e42b";
}
.icon-wb_auto:before {
    content: "\e42c";
}
.icon-wb_cloudy:before {
    content: "\e42d";
}
.icon-wb_incandescent:before {
    content: "\e42e";
}
.icon-wb_sunny:before {
    content: "\e430";
}
.icon-collections_bookmark:before {
    content: "\e431";
}
.icon-photo_size_select_actual:before {
    content: "\e432";
}
.icon-photo_size_select_large:before {
    content: "\e433";
}
.icon-photo_size_select_small:before {
    content: "\e434";
}
.icon-vignette:before {
    content: "\e435";
}
.icon-wb_iridescent:before {
    content: "\e436";
}
.icon-crop_rotate:before {
    content: "\e437";
}
.icon-linked_camera:before {
    content: "\e438";
}
.icon-add_a_photo:before {
    content: "\e439";
}
.icon-movie_filter:before {
    content: "\e43a";
}
.icon-photo_filter:before {
    content: "\e43b";
}
.icon-beenhere:before {
    content: "\e52d";
}
.icon-directions:before {
    content: "\e52e";
}
.icon-directions_bike:before {
    content: "\e52f";
}
.icon-directions_bus:before {
    content: "\e530";
}
.icon-directions_car:before {
    content: "\e531";
}
.icon-directions_ferry:before {
    content: "\e532";
}
.icon-directions_subway:before {
    content: "\e533";
}
.icon-directions_railway:before {
    content: "\e534";
}
.icon-directions_transit:before {
    content: "\e535";
}
.icon-directions_walk:before {
    content: "\e536";
}
.icon-flight:before {
    content: "\e539";
}
.icon-hotel:before {
    content: "\e53a";
}
.icon-layers:before {
    content: "\e53b";
}
.icon-layers_clear:before {
    content: "\e53c";
}
.icon-local_airport:before {
    content: "\e53d";
}
.icon-local_atm:before {
    content: "\e53e";
}
.icon-local_attraction:before {
    content: "\e53f";
}
.icon-local_bar:before {
    content: "\e540";
}
.icon-local_cafe:before {
    content: "\e541";
}
.icon-local_car_wash:before {
    content: "\e542";
}
.icon-local_convenience_store:before {
    content: "\e543";
}
.icon-local_drink:before {
    content: "\e544";
}
.icon-local_florist:before {
    content: "\e545";
}
.icon-local_gas_station:before {
    content: "\e546";
}
.icon-local_grocery_store:before {
    content: "\e547";
}
.icon-local_hospital:before {
    content: "\e548";
}
.icon-local_hotel:before {
    content: "\e549";
}
.icon-local_laundry_service:before {
    content: "\e54a";
}
.icon-local_library:before {
    content: "\e54b";
}
.icon-local_mall:before {
    content: "\e54c";
}
.icon-local_movies:before {
    content: "\e54d";
}
.icon-local_offer:before {
    content: "\e54e";
}
.icon-local_parking:before {
    content: "\e54f";
}
.icon-local_pharmacy:before {
    content: "\e550";
}
.icon-local_phone:before {
    content: "\e551";
}
.icon-local_pizza:before {
    content: "\e552";
}
.icon-local_play:before {
    content: "\e553";
}
.icon-local_post_office:before {
    content: "\e554";
}
.icon-local_print_shop:before {
    content: "\e555";
}
.icon-local_restaurant:before {
    content: "\e556";
}
.icon-local_see:before {
    content: "\e557";
}
.icon-local_shipping:before {
    content: "\e558";
}
.icon-local_taxi:before {
    content: "\e559";
}
.icon-location_history:before {
    content: "\e55a";
}
.icon-map:before {
    content: "\e55b";
}
.icon-my_location:before {
    content: "\e55c";
}
.icon-navigation:before {
    content: "\e55d";
}
.icon-pin_drop:before {
    content: "\e55e";
}
.icon-place:before {
    content: "\e55f";
}
.icon-rate_review:before {
    content: "\e560";
}
.icon-restaurant_menu:before {
    content: "\e561";
}
.icon-satellite:before {
    content: "\e562";
}
.icon-store_mall_directory:before {
    content: "\e563";
}
.icon-terrain:before {
    content: "\e564";
}
.icon-traffic:before {
    content: "\e565";
}
.icon-directions_run:before {
    content: "\e566";
}
.icon-add_location:before {
    content: "\e567";
}
.icon-edit_location:before {
    content: "\e568";
}
.icon-near_me:before {
    content: "\e569";
}
.icon-person_pin_circle:before {
    content: "\e56a";
}
.icon-zoom_out_map:before {
    content: "\e56b";
}
.icon-apps:before {
    content: "\e5c3";
}
.icon-arrow_back:before {
    content: "\e5c4";
}
.icon-arrow_drop_down:before {
    content: "\e5c5";
}
.icon-arrow_drop_down_circle:before {
    content: "\e5c6";
}
.icon-arrow_drop_up:before {
    content: "\e5c7";
}
.icon-arrow_forward:before {
    content: "\e5c8";
}
.icon-cancel:before {
    content: "\e5c9";
}
.icon-check:before {
    content: "\e5ca";
}
.icon-chevron_left:before {
    content: "\e5cb";
}
.icon-chevron_right:before {
    content: "\e5cc";
}
.icon-close:before {
    content: "\e5cd";
}
.icon-expand_less:before {
    content: "\e5ce";
}
.icon-expand_more:before {
    content: "\e5cf";
}
.icon-fullscreen:before {
    content: "\e5d0";
}
.icon-fullscreen_exit:before {
    content: "\e5d1";
}
.icon-menu:before {
    content: "\e5d2";
}
.icon-keyboard_control:before {
    content: "\e5d3";
}
.icon-more_vert:before {
    content: "\e5d4";
}
.icon-refresh:before {
    content: "\e5d5";
}
.icon-unfold_less:before {
    content: "\e5d6";
}
.icon-unfold_more:before {
    content: "\e5d7";
}
.icon-arrow_upward:before {
    content: "\e5d8";
}
.icon-subdirectory_arrow_left:before {
    content: "\e5d9";
}
.icon-subdirectory_arrow_right:before {
    content: "\e5da";
}
.icon-arrow_downward:before {
    content: "\e5db";
}
.icon-adb:before {
    content: "\e60e";
}
.icon-bluetooth_audio:before {
    content: "\e60f";
}
.icon-disc_full:before {
    content: "\e610";
}
.icon-do_not_disturb_alt:before {
    content: "\e611";
}
.icon-do_not_disturb:before {
    content: "\e612";
}
.icon-drive_eta:before {
    content: "\e613";
}
.icon-event_available:before {
    content: "\e614";
}
.icon-event_busy:before {
    content: "\e615";
}
.icon-event_note:before {
    content: "\e616";
}
.icon-folder_special:before {
    content: "\e617";
}
.icon-mms:before {
    content: "\e618";
}
.icon-more:before {
    content: "\e619";
}
.icon-network_locked:before {
    content: "\e61a";
}
.icon-phone_bluetooth_speaker:before {
    content: "\e61b";
}
.icon-phone_forwarded:before {
    content: "\e61c";
}
.icon-phone_in_talk:before {
    content: "\e61d";
}
.icon-phone_locked:before {
    content: "\e61e";
}
.icon-phone_missed:before {
    content: "\e61f";
}
.icon-phone_paused:before {
    content: "\e620";
}
.icon-sd_card:before {
    content: "\e623";
}
.icon-sim_card_alert:before {
    content: "\e624";
}
.icon-sms:before {
    content: "\e625";
}
.icon-sms_failed:before {
    content: "\e626";
}
.icon-sync:before {
    content: "\e627";
}
.icon-sync_disabled:before {
    content: "\e628";
}
.icon-sync_problem:before {
    content: "\e629";
}
.icon-system_update:before {
    content: "\e62a";
}
.icon-tap_and_play:before {
    content: "\e62b";
}
.icon-time_to_leave:before {
    content: "\e62c";
}
.icon-vibration:before {
    content: "\e62d";
}
.icon-voice_chat:before {
    content: "\e62e";
}
.icon-vpn_lock:before {
    content: "\e62f";
}
.icon-airline_seat_flat:before {
    content: "\e630";
}
.icon-airline_seat_flat_angled:before {
    content: "\e631";
}
.icon-airline_seat_individual_suite:before {
    content: "\e632";
}
.icon-airline_seat_legroom_extra:before {
    content: "\e633";
}
.icon-airline_seat_legroom_normal:before {
    content: "\e634";
}
.icon-airline_seat_legroom_reduced:before {
    content: "\e635";
}
.icon-airline_seat_recline_extra:before {
    content: "\e636";
}
.icon-airline_seat_recline_normal:before {
    content: "\e637";
}
.icon-confirmation_number:before {
    content: "\e638";
}
.icon-live_tv:before {
    content: "\e639";
}
.icon-ondemand_video:before {
    content: "\e63a";
}
.icon-personal_video:before {
    content: "\e63b";
}
.icon-power:before {
    content: "\e63c";
}
.icon-wc:before {
    content: "\e63d";
}
.icon-wifi:before {
    content: "\e63e";
}
.icon-enhanced_encryption:before {
    content: "\e63f";
}
.icon-network_check:before {
    content: "\e640";
}
.icon-no_encryption:before {
    content: "\e641";
}
.icon-rv_hookup:before {
    content: "\e642";
}
.icon-cake:before {
    content: "\e7e9";
}
.icon-domain:before {
    content: "\e7ee";
}
.icon-group:before {
    content: "\e7ef";
}
.icon-group_add:before {
    content: "\e7f0";
}
.icon-location_city:before {
    content: "\e7f1";
}
.icon-mood:before {
    content: "\e7f2";
}
.icon-mood_bad:before {
    content: "\e7f3";
}
.icon-notifications:before {
    content: "\e7f4";
}
.icon-notifications_none:before {
    content: "\e7f5";
}
.icon-notifications_off:before {
    content: "\e7f6";
}
.icon-notifications_active:before {
    content: "\e7f7";
}
.icon-notifications_paused:before {
    content: "\e7f8";
}
.icon-pages:before {
    content: "\e7f9";
}
.icon-party_mode:before {
    content: "\e7fa";
}
.icon-people:before {
    content: "\e7fb";
}
.icon-people_outline:before {
    content: "\e7fc";
}
.icon-person:before {
    content: "\e7fd";
}
.icon-person_add:before {
    content: "\e7fe";
}
.icon-person_outline:before {
    content: "\e7ff";
}
.icon-plus_one:before {
    content: "\e800";
}
.icon-poll:before {
    content: "\e801";
}
.icon-public:before {
    content: "\e80b";
}
.icon-school:before {
    content: "\e80c";
}
.icon-share:before {
    content: "\e80d";
}
.icon-whatshot:before {
    content: "\e80e";
}
.icon-check_box:before {
    content: "\e834";
}
.icon-check_box_outline_blank:before {
    content: "\e835";
}
.icon-radio_button_unchecked:before {
    content: "\e836";
}
.icon-radio_button_checked:before {
    content: "\e837";
}
.icon-star:before {
    content: "\e838";
}
.icon-star_half:before {
    content: "\e839";
}
.icon-star_outline:before {
    content: "\e83a";
}
.icon-3d_rotation:before {
    content: "\e84d";
}
.icon-accessibility:before {
    content: "\e84e";
}
.icon-account_balance:before {
    content: "\e84f";
}
.icon-account_balance_wallet:before {
    content: "\e850";
}
.icon-account_box:before {
    content: "\e851";
}
.icon-account_circle:before {
    content: "\e853";
}
.icon-add_shopping_cart:before {
    content: "\e854";
}
.icon-alarm:before {
    content: "\e855";
}
.icon-alarm_add:before {
    content: "\e856";
}
.icon-alarm_off:before {
    content: "\e857";
}
.icon-alarm_on:before {
    content: "\e858";
}
.icon-android:before {
    content: "\e859";
}
.icon-announcement:before {
    content: "\e85a";
}
.icon-aspect_ratio:before {
    content: "\e85b";
}
.icon-assessment:before {
    content: "\e85c";
}
.icon-assignment:before {
    content: "\e85d";
}
.icon-assignment_ind:before {
    content: "\e85e";
}
.icon-assignment_late:before {
    content: "\e85f";
}
.icon-assignment_return:before {
    content: "\e860";
}
.icon-assignment_returned:before {
    content: "\e861";
}
.icon-assignment_turned_in:before {
    content: "\e862";
}
.icon-autorenew:before {
    content: "\e863";
}
.icon-backup:before {
    content: "\e864";
}
.icon-book:before {
    content: "\e865";
}
.icon-bookmark:before {
    content: "\e866";
}
.icon-bookmark_outline:before {
    content: "\e867";
}
.icon-bug_report:before {
    content: "\e868";
}
.icon-build:before {
    content: "\e869";
}
.icon-cached:before {
    content: "\e86a";
}
.icon-change_history:before {
    content: "\e86b";
}
.icon-check_circle:before {
    content: "\e86c";
}
.icon-chrome_reader_mode:before {
    content: "\e86d";
}
.icon-class:before {
    content: "\e86e";
}
.icon-code:before {
    content: "\e86f";
}
.icon-credit_card:before {
    content: "\e870";
}
.icon-dashboard:before {
    content: "\e871";
}
.icon-delete:before {
    content: "\e872";
}
.icon-description:before {
    content: "\e873";
}
.icon-dns:before {
    content: "\e875";
}
.icon-done:before {
    content: "\e876";
}
.icon-done_all:before {
    content: "\e877";
}
.icon-event:before {
    content: "\e878";
}
.icon-exit_to_app:before {
    content: "\e879";
}
.icon-explore:before {
    content: "\e87a";
}
.icon-extension:before {
    content: "\e87b";
}
.icon-face:before {
    content: "\e87c";
}
.icon-favorite:before {
    content: "\e87d";
}
.icon-favorite_outline:before {
    content: "\e87e";
}
.icon-feedback:before {
    content: "\e87f";
}
.icon-find_in_page:before {
    content: "\e880";
}
.icon-find_replace:before {
    content: "\e881";
}
.icon-flip_to_back:before {
    content: "\e882";
}
.icon-flip_to_front:before {
    content: "\e883";
}
.icon-get_app:before {
    content: "\e884";
}
.icon-grade:before {
    content: "\e885";
}
.icon-group_work:before {
    content: "\e886";
}
.icon-help:before {
    content: "\e887";
}
.icon-highlight_remove:before {
    content: "\e888";
}
.icon-history:before {
    content: "\e889";
}
.icon-home:before {
    content: "\e88a";
}
.icon-hourglass_empty:before {
    content: "\e88b";
}
.icon-hourglass_full:before {
    content: "\e88c";
}
.icon-https:before {
    content: "\e88d";
}
.icon-info:before {
    content: "\e88e";
}
.icon-info_outline:before {
    content: "\e88f";
}
.icon-input:before {
    content: "\e890";
}
.icon-invert_colors_on:before {
    content: "\e891";
}
.icon-label:before {
    content: "\e892";
}
.icon-label_outline:before {
    content: "\e893";
}
.icon-language:before {
    content: "\e894";
}
.icon-launch:before {
    content: "\e895";
}
.icon-list:before {
    content: "\e896";
}
.icon-lock:before {
    content: "\e897";
}
.icon-lock_open:before {
    content: "\e898";
}
.icon-lock_outline:before {
    content: "\e899";
}
.icon-loyalty:before {
    content: "\e89a";
}
.icon-markunread_mailbox:before {
    content: "\e89b";
}
.icon-note_add:before {
    content: "\e89c";
}
.icon-open_in_browser:before {
    content: "\e89d";
}
.icon-open_in_new:before {
    content: "\e89e";
}
.icon-open_with:before {
    content: "\e89f";
}
.icon-pageview:before {
    content: "\e8a0";
}
.icon-payment:before {
    content: "\e8a1";
}
.icon-perm_camera_mic:before {
    content: "\e8a2";
}
.icon-perm_contact_calendar:before {
    content: "\e8a3";
}
.icon-perm_data_setting:before {
    content: "\e8a4";
}
.icon-perm_device_information:before {
    content: "\e8a5";
}
.icon-perm_identity:before {
    content: "\e8a6";
}
.icon-perm_media:before {
    content: "\e8a7";
}
.icon-perm_phone_msg:before {
    content: "\e8a8";
}
.icon-perm_scan_wifi:before {
    content: "\e8a9";
}
.icon-picture_in_picture:before {
    content: "\e8aa";
}
.icon-polymer:before {
    content: "\e8ab";
}
.icon-power_settings_new:before {
    content: "\e8ac";
}
.icon-print:before {
    content: "\e8ad";
}
.icon-query_builder:before {
    content: "\e8ae";
}
.icon-question_answer:before {
    content: "\e8af";
}
.icon-receipt:before {
    content: "\e8b0";
}
.icon-redeem:before {
    content: "\e8b1";
}
.icon-report_problem:before {
    content: "\e8b2";
}
.icon-restore:before {
    content: "\e8b3";
}
.icon-room:before {
    content: "\e8b4";
}
.icon-schedule:before {
    content: "\e8b5";
}
.icon-search:before {
    content: "\e8b6";
}
.icon-settings:before {
    content: "\e8b8";
}
.icon-settings_applications:before {
    content: "\e8b9";
}
.icon-settings_backup_restore:before {
    content: "\e8ba";
}
.icon-settings_bluetooth:before {
    content: "\e8bb";
}
.icon-settings_cell:before {
    content: "\e8bc";
}
.icon-settings_brightness:before {
    content: "\e8bd";
}
.icon-settings_ethernet:before {
    content: "\e8be";
}
.icon-settings_input_antenna:before {
    content: "\e8bf";
}
.icon-settings_input_component:before {
    content: "\e8c0";
}
.icon-settings_input_composite:before {
    content: "\e8c1";
}
.icon-settings_input_hdmi:before {
    content: "\e8c2";
}
.icon-settings_input_svideo:before {
    content: "\e8c3";
}
.icon-settings_overscan:before {
    content: "\e8c4";
}
.icon-settings_phone:before {
    content: "\e8c5";
}
.icon-settings_power:before {
    content: "\e8c6";
}
.icon-settings_remote:before {
    content: "\e8c7";
}
.icon-settings_voice:before {
    content: "\e8c8";
}
.icon-shop:before {
    content: "\e8c9";
}
.icon-shop_two:before {
    content: "\e8ca";
}
.icon-shopping_basket:before {
    content: "\e8cb";
}
.icon-shopping_cart:before {
    content: "\e8cc";
}
.icon-speaker_notes:before {
    content: "\e8cd";
}
.icon-spellcheck:before {
    content: "\e8ce";
}
.icon-stars:before {
    content: "\e8d0";
}
.icon-store:before {
    content: "\e8d1";
}
.icon-subject:before {
    content: "\e8d2";
}
.icon-supervisor_account:before {
    content: "\e8d3";
}
.icon-swap_horiz:before {
    content: "\e8d4";
}
.icon-swap_vert:before {
    content: "\e8d5";
}
.icon-swap_vertical_circle:before {
    content: "\e8d6";
}
.icon-system_update_alt:before {
    content: "\e8d7";
}
.icon-tab:before {
    content: "\e8d8";
}
.icon-tab_unselected:before {
    content: "\e8d9";
}
.icon-theaters:before {
    content: "\e8da";
}
.icon-thumb_down:before {
    content: "\e8db";
}
.icon-thumb_up:before {
    content: "\e8dc";
}
.icon-thumbs_up_down:before {
    content: "\e8dd";
}
.icon-toc:before {
    content: "\e8de";
}
.icon-today:before {
    content: "\e8df";
}
.icon-toll:before {
    content: "\e8e0";
}
.icon-track_changes:before {
    content: "\e8e1";
}
.icon-translate:before {
    content: "\e8e2";
}
.icon-trending_down:before {
    content: "\e8e3";
}
.icon-trending_neutral:before {
    content: "\e8e4";
}
.icon-trending_up:before {
    content: "\e8e5";
}
.icon-turned_in:before {
    content: "\e8e6";
}
.icon-turned_in_not:before {
    content: "\e8e7";
}
.icon-verified_user:before {
    content: "\e8e8";
}
.icon-view_agenda:before {
    content: "\e8e9";
}
.icon-view_array:before {
    content: "\e8ea";
}
.icon-view_carousel:before {
    content: "\e8eb";
}
.icon-view_column:before {
    content: "\e8ec";
}
.icon-view_day:before {
    content: "\e8ed";
}
.icon-view_headline:before {
    content: "\e8ee";
}
.icon-view_list:before {
    content: "\e8ef";
}
.icon-view_module:before {
    content: "\e8f0";
}
.icon-view_quilt:before {
    content: "\e8f1";
}
.icon-view_stream:before {
    content: "\e8f2";
}
.icon-view_week:before {
    content: "\e8f3";
}
.icon-visibility:before {
    content: "\e8f4";
}
.icon-visibility_off:before {
    content: "\e8f5";
}
.icon-card_giftcard:before {
    content: "\e8f6";
}
.icon-card_membership:before {
    content: "\e8f7";
}
.icon-card_travel:before {
    content: "\e8f8";
}
.icon-work:before {
    content: "\e8f9";
}
.icon-youtube_searched_for:before {
    content: "\e8fa";
}
.icon-eject:before {
    content: "\e8fb";
}
.icon-camera_enhance:before {
    content: "\e8fc";
}
.icon-help_outline:before {
    content: "\e8fd";
}
.icon-reorder:before {
    content: "\e8fe";
}
.icon-zoom_in:before {
    content: "\e8ff";
}
.icon-zoom_out:before {
    content: "\e900";
}
.icon-http:before {
    content: "\e902";
}
.icon-event_seat:before {
    content: "\e903";
}
.icon-flight_land:before {
    content: "\e904";
}
.icon-flight_takeoff:before {
    content: "\e905";
}
.icon-play_for_work:before {
    content: "\e906";
}
.icon-gif:before {
    content: "\e908";
}
.icon-indeterminate_check_box:before {
    content: "\e909";
}
.icon-offline_pin:before {
    content: "\e90a";
}
.icon-all_out:before {
    content: "\e90b";
}
.icon-copyright:before {
    content: "\e90c";
}
.icon-fingerprint:before {
    content: "\e90d";
}
.icon-gavel:before {
    content: "\e90e";
}
.icon-lightbulb_outline:before {
    content: "\e90f";
}
.icon-picture_in_picture_alt:before {
    content: "\e911";
}
.icon-important_devices:before {
    content: "\e912";
}
.icon-touch_app:before {
    content: "\e913";
}
.icon-accessible:before {
    content: "\e914";
}
.icon-compare_arrows:before {
    content: "\e915";
}
.icon-date_range:before {
    content: "\e916";
}
.icon-donut_large:before {
    content: "\e917";
}
.icon-donut_small:before {
    content: "\e918";
}
.icon-line_style:before {
    content: "\e919";
}
.icon-line_weight:before {
    content: "\e91a";
}
.icon-motorcycle:before {
    content: "\e91b";
}
.icon-opacity:before {
    content: "\e91c";
}
.icon-pets:before {
    content: "\e91d";
}
.icon-pregnant_woman:before {
    content: "\e91e";
}
.icon-record_voice_over:before {
    content: "\e91f";
}
.icon-rounded_corner:before {
    content: "\e920";
}
.icon-rowing:before {
    content: "\e921";
}
.icon-timeline:before {
    content: "\e922";
}
.icon-update:before {
    content: "\e923";
}
.icon-watch_later:before {
    content: "\e924";
}
.icon-pan_tool:before {
    content: "\e925";
}
.icon-ac_unit:before {
    content: "\eb3b";
}
.icon-airport_shuttle:before {
    content: "\eb3c";
}
.icon-all_inclusive:before {
    content: "\eb3d";
}
.icon-beach_access:before {
    content: "\eb3e";
}
.icon-business_center:before {
    content: "\eb3f";
}
.icon-casino:before {
    content: "\eb40";
}
.icon-child_care:before {
    content: "\eb41";
}
.icon-child_friendly:before {
    content: "\eb42";
}
.icon-fitness_center:before {
    content: "\eb43";
}
.icon-free_breakfast:before {
    content: "\eb44";
}
.icon-golf_course:before {
    content: "\eb45";
}
.icon-hot_tub:before {
    content: "\eb46";
}
.icon-kitchen:before {
    content: "\eb47";
}
.icon-pool:before {
    content: "\eb48";
}
.icon-room_service:before {
    content: "\eb49";
}
.icon-smoke_free:before {
    content: "\eb4a";
}
.icon-smoking_rooms:before {
    content: "\eb4b";
}
.icon-spa:before {
    content: "\eb4c";
}
.icon-goat:before {
    content: "\e901";
}
.icon-glass:before {
    content: "\f000";
}
.icon-music:before {
    content: "\f001";
}
.icon-search2:before {
    content: "\f002";
}
.icon-envelope-o:before {
    content: "\f003";
}
.icon-heart:before {
    content: "\f004";
}
.icon-star2:before {
    content: "\f005";
}
.icon-star-o:before {
    content: "\f006";
}
.icon-user:before {
    content: "\f007";
}
.icon-film:before {
    content: "\f008";
}
.icon-th-large:before {
    content: "\f009";
}
.icon-th:before {
    content: "\f00a";
}
.icon-th-list:before {
    content: "\f00b";
}
.icon-check2:before {
    content: "\f00c";
}
.icon-close2:before {
    content: "\f00d";
}
.icon-remove2:before {
    content: "\f00d";
}
.icon-times:before {
    content: "\f00d";
}
.icon-search-plus:before {
    content: "\f00e";
}
.icon-search-minus:before {
    content: "\f010";
}
.icon-power-off:before {
    content: "\f011";
}
.icon-signal:before {
    content: "\f012";
}
.icon-cog:before {
    content: "\f013";
}
.icon-gear:before {
    content: "\f013";
}
.icon-trash-o:before {
    content: "\f014";
}
.icon-home2:before {
    content: "\f015";
}
.icon-file-o:before {
    content: "\f016";
}
.icon-clock-o:before {
    content: "\f017";
}
.icon-road:before {
    content: "\f018";
}
.icon-download:before {
    content: "\f019";
}
.icon-arrow-circle-o-down:before {
    content: "\f01a";
}
.icon-arrow-circle-o-up:before {
    content: "\f01b";
}
.icon-inbox2:before {
    content: "\f01c";
}
.icon-play-circle-o:before {
    content: "\f01d";
}
.icon-repeat2:before {
    content: "\f01e";
}
.icon-rotate-right:before {
    content: "\f01e";
}
.icon-refresh2:before {
    content: "\f021";
}
.icon-list-alt:before {
    content: "\f022";
}
.icon-lock2:before {
    content: "\f023";
}
.icon-flag2:before {
    content: "\f024";
}
.icon-headphones:before {
    content: "\f025";
}
.icon-volume-off:before {
    content: "\f026";
}
.icon-volume-down:before {
    content: "\f027";
}
.icon-volume-up:before {
    content: "\f028";
}
.icon-qrcode:before {
    content: "\f029";
}
.icon-barcode:before {
    content: "\f02a";
}
.icon-tag:before {
    content: "\f02b";
}
.icon-tags:before {
    content: "\f02c";
}
.icon-book2:before {
    content: "\f02d";
}
.icon-bookmark2:before {
    content: "\f02e";
}
.icon-print2:before {
    content: "\f02f";
}
.icon-camera2:before {
    content: "\f030";
}
.icon-font:before {
    content: "\f031";
}
.icon-bold:before {
    content: "\f032";
}
.icon-italic:before {
    content: "\f033";
}
.icon-text-height:before {
    content: "\f034";
}
.icon-text-width:before {
    content: "\f035";
}
.icon-align-left:before {
    content: "\f036";
}
.icon-align-center:before {
    content: "\f037";
}
.icon-align-right:before {
    content: "\f038";
}
.icon-align-justify:before {
    content: "\f039";
}
.icon-list2:before {
    content: "\f03a";
}
.icon-dedent:before {
    content: "\f03b";
}
.icon-outdent:before {
    content: "\f03b";
}
.icon-indent:before {
    content: "\f03c";
}
.icon-video-camera:before {
    content: "\f03d";
}
.icon-image2:before {
    content: "\f03e";
}
.icon-photo2:before {
    content: "\f03e";
}
.icon-picture-o:before {
    content: "\f03e";
}
.icon-pencil:before {
    content: "\f040";
}
.icon-map-marker:before {
    content: "\f041";
}
.icon-adjust2:before {
    content: "\f042";
}
.icon-tint:before {
    content: "\f043";
}
.icon-edit2:before {
    content: "\f044";
}
.icon-pencil-square-o:before {
    content: "\f044";
}
.icon-share-square-o:before {
    content: "\f045";
}
.icon-check-square-o:before {
    content: "\f046";
}
.icon-arrows:before {
    content: "\f047";
}
.icon-step-backward:before {
    content: "\f048";
}
.icon-fast-backward:before {
    content: "\f049";
}
.icon-backward:before {
    content: "\f04a";
}
.icon-play:before {
    content: "\f04b";
}
.icon-pause2:before {
    content: "\f04c";
}
.icon-stop2:before {
    content: "\f04d";
}
.icon-forward2:before {
    content: "\f04e";
}
.icon-fast-forward:before {
    content: "\f050";
}
.icon-step-forward:before {
    content: "\f051";
}
.icon-eject2:before {
    content: "\f052";
}
.icon-chevron-left:before {
    content: "\f053";
}
.icon-chevron-right:before {
    content: "\f054";
}
.icon-plus-circle:before {
    content: "\f055";
}
.icon-minus-circle:before {
    content: "\f056";
}
.icon-times-circle:before {
    content: "\f057";
}
.icon-check-circle:before {
    content: "\f058";
}
.icon-question-circle:before {
    content: "\f059";
}
.icon-info-circle:before {
    content: "\f05a";
}
.icon-crosshairs:before {
    content: "\f05b";
}
.icon-times-circle-o:before {
    content: "\f05c";

}
.icon-check-circle-o:before {
    content: "\f05d";
}
.icon-ban:before {
    content: "\f05e";
}
.icon-arrow-left:before {
    content: "\f060";
}
.icon-arrow-right:before {
    content: "\f061";
}
.icon-arrow-up:before {
    content: "\f062";
}
.icon-arrow-down:before {
    content: "\f063";
}
.icon-mail-forward:before {
    content: "\f064";
}
.icon-share2:before {
    content: "\f064";
}
.icon-expand:before {
    content: "\f065";
}
.icon-compress:before {
    content: "\f066";
}
.icon-plus:before {
    content: "\f067";
}
.icon-minus:before {
    content: "\f068";
}
.icon-asterisk:before {
    content: "\f069";
}
.icon-exclamation-circle:before {
    content: "\f06a";
}
.icon-gift:before {
    content: "\f06b";
}
.icon-leaf:before {
    content: "\f06c";
}
.icon-fire:before {
    content: "\f06d";
}
.icon-eye:before {
    content: "\f06e";
}
.icon-eye-slash:before {
    content: "\f070";
}
.icon-exclamation-triangle:before {
    content: "\f071";
}
.icon-warning2:before {
    content: "\f071";
}
.icon-plane:before {
    content: "\f072";
}
.icon-calendar:before {
    content: "\f073";
}
.icon-random:before {
    content: "\f074";
}
.icon-comment2:before {
    content: "\f075";
}
.icon-magnet:before {
    content: "\f076";
}
.icon-chevron-up:before {
    content: "\f077";
}
.icon-chevron-down:before {
    content: "\f078";
}
.icon-retweet:before {
    content: "\f079";
}
.icon-shopping-cart:before {
    content: "\f07a";
}
.icon-folder2:before {
    content: "\f07b";
}
.icon-folder-open:before {
    content: "\f07c";
}
.icon-arrows-v:before {
    content: "\f07d";
}
.icon-arrows-h:before {
    content: "\f07e";
}
.icon-bar-chart:before {
    content: "\f080";
}
.icon-bar-chart-o:before {
    content: "\f080";
}
.icon-twitter-square:before {
    content: "\f081";
}
.icon-facebook-square:before {
    content: "\f082";
}
.icon-camera-retro:before {
    content: "\f083";
}
.icon-key:before {
    content: "\f084";
}
.icon-cogs:before {
    content: "\f085";
}
.icon-gears:before {
    content: "\f085";
}
.icon-comments:before {
    content: "\f086";
}
.icon-thumbs-o-up:before {
    content: "\f087";
}
.icon-thumbs-o-down:before {
    content: "\f088";
}
.icon-star-half:before {
    content: "\f089";
}
.icon-heart-o:before {
    content: "\f08a";
}
.icon-sign-out:before {
    content: "\f08b";
}
.icon-linkedin-square:before {
    content: "\f08c";
}
.icon-thumb-tack:before {
    content: "\f08d";
}
.icon-external-link:before {
    content: "\f08e";
}
.icon-sign-in:before {
    content: "\f090";
}
.icon-trophy:before {
    content: "\f091";
}
.icon-github-square:before {
    content: "\f092";
}
.icon-upload:before {
    content: "\f093";
}
.icon-lemon-o:before {
    content: "\f094";
}
.icon-phone2:before {
    content: "\f095";
}
.icon-square-o:before {
    content: "\f096";
}
.icon-bookmark-o:before {
    content: "\f097";
}
.icon-phone-square:before {
    content: "\f098";
}
.icon-twitter:before {
    content: "\f099";
}
.icon-facebook:before {
    content: "\f09a";
}
.icon-facebook-f:before {
    content: "\f09a";
}
.icon-github:before {
    content: "\f09b";
}
.icon-unlock:before {
    content: "\f09c";
}
.icon-credit-card:before {
    content: "\f09d";
}
.icon-feed:before {
    content: "\f09e";
}
.icon-rss:before {
    content: "\f09e";
}
.icon-hdd-o:before {
    content: "\f0a0";
}
.icon-bullhorn:before {
    content: "\f0a1";
}
.icon-bell-o:before {
    content: "\f0a2";
}
.icon-certificate:before {
    content: "\f0a3";
}
.icon-hand-o-right:before {
    content: "\f0a4";
}
.icon-hand-o-left:before {
    content: "\f0a5";
}
.icon-hand-o-up:before {
    content: "\f0a6";
}
.icon-hand-o-down:before {
    content: "\f0a7";
}
.icon-arrow-circle-left:before {
    content: "\f0a8";
}
.icon-arrow-circle-right:before {
    content: "\f0a9";
}
.icon-arrow-circle-up:before {
    content: "\f0aa";
}
.icon-arrow-circle-down:before {
    content: "\f0ab";
}
.icon-globe:before {
    content: "\f0ac";
}
.icon-wrench:before {
    content: "\f0ad";
}
.icon-tasks:before {
    content: "\f0ae";
}
.icon-filter2:before {
    content: "\f0b0";
}
.icon-briefcase:before {
    content: "\f0b1";
}
.icon-arrows-alt:before {
    content: "\f0b2";
}
.icon-group2:before {
    content: "\f0c0";
}
.icon-users:before {
    content: "\f0c0";
}
.icon-chain:before {
    content: "\f0c1";
}
.icon-link2:before {
    content: "\f0c1";
}
.icon-cloud2:before {
    content: "\f0c2";
}
.icon-flask:before {
    content: "\f0c3";
}
.icon-cut:before {
    content: "\f0c4";
}
.icon-scissors:before {
    content: "\f0c4";
}
.icon-copy:before {
    content: "\f0c5";
}
.icon-files-o:before {
    content: "\f0c5";
}
.icon-paperclip:before {
    content: "\f0c6";
}
.icon-floppy-o:before {
    content: "\f0c7";
}
.icon-save2:before {
    content: "\f0c7";
}
.icon-square:before {
    content: "\f0c8";
}
.icon-bars:before {
    content: "\f0c9";
}
.icon-navicon:before {
    content: "\f0c9";
}
.icon-reorder2:before {
    content: "\f0c9";
}
.icon-list-ul:before {
    content: "\f0ca";
}
.icon-list-ol:before {
    content: "\f0cb";
}
.icon-strikethrough:before {
    content: "\f0cc";
}
.icon-underline:before {
    content: "\f0cd";
}
.icon-table:before {
    content: "\f0ce";
}
.icon-magic:before {
    content: "\f0d0";
}
.icon-truck:before {
    content: "\f0d1";
}
.icon-pinterest:before {
    content: "\f0d2";
}
.icon-pinterest-square:before {
    content: "\f0d3";
}
.icon-google-plus-square:before {
    content: "\f0d4";
}
.icon-google-plus:before {
    content: "\f0d5";
}
.icon-money:before {
    content: "\f0d6";
}
.icon-caret-down:before {
    content: "\f0d7";
}
.icon-caret-up:before {
    content: "\f0d8";
}
.icon-caret-left:before {
    content: "\f0d9";
}
.icon-caret-right:before {
    content: "\f0da";
}
.icon-columns:before {
    content: "\f0db";
}
.icon-sort2:before {
    content: "\f0dc";
}
.icon-unsorted:before {
    content: "\f0dc";
}
.icon-sort-desc:before {
    content: "\f0dd";
}
.icon-sort-down:before {
    content: "\f0dd";
}
.icon-sort-asc:before {
    content: "\f0de";
}
.icon-sort-up:before {
    content: "\f0de";
}
.icon-envelope:before {
    content: "\f0e0";
}
.icon-linkedin:before {
    content: "\f0e1";
}
.icon-rotate-left:before {
    content: "\f0e2";
}
.icon-undo2:before {
    content: "\f0e2";
}
.icon-gavel2:before {
    content: "\f0e3";
}
.icon-legal:before {
    content: "\f0e3";
}
.icon-dashboard2:before {
    content: "\f0e4";
}
.icon-tachometer:before {
    content: "\f0e4";
}
.icon-comment-o:before {
    content: "\f0e5";
}
.icon-comments-o:before {
    content: "\f0e6";
}
.icon-bolt:before {
    content: "\f0e7";
}
.icon-flash:before {
    content: "\f0e7";
}
.icon-sitemap:before {
    content: "\f0e8";
}
.icon-umbrella:before {
    content: "\f0e9";
}
.icon-clipboard:before {
    content: "\f0ea";
}
.icon-paste:before {
    content: "\f0ea";
}
.icon-lightbulb-o:before {
    content: "\f0eb";
}
.icon-exchange:before {
    content: "\f0ec";
}
.icon-cloud-download:before {
    content: "\f0ed";
}
.icon-cloud-upload:before {
    content: "\f0ee";
}
.icon-user-md:before {
    content: "\f0f0";
}
.icon-stethoscope:before {
    content: "\f0f1";
}
.icon-suitcase:before {
    content: "\f0f2";
}
.icon-bell:before {
    content: "\f0f3";
}
.icon-coffee:before {
    content: "\f0f4";
}
.icon-cutlery:before {
    content: "\f0f5";
}
.icon-file-text-o:before {
    content: "\f0f6";
}
.icon-building-o:before {
    content: "\f0f7";
}
.icon-hospital-o:before {
    content: "\f0f8";
}
.icon-ambulance:before {
    content: "\f0f9";
}
.icon-medkit:before {
    content: "\f0fa";
}
.icon-fighter-jet:before {
    content: "\f0fb";
}
.icon-beer:before {
    content: "\f0fc";
}
.icon-h-square:before {
    content: "\f0fd";
}
.icon-plus-square:before {
    content: "\f0fe";
}
.icon-angle-double-left:before {
    content: "\f100";
}
.icon-angle-double-right:before {
    content: "\f101";
}
.icon-angle-double-up:before {
    content: "\f102";
}
.icon-angle-double-down:before {
    content: "\f103";
}
.icon-angle-left:before {
    content: "\f104";
}
.icon-angle-right:before {
    content: "\f105";
}
.icon-angle-up:before {
    content: "\f106";
}
.icon-angle-down:before {
    content: "\f107";
}
.icon-desktop:before {
    content: "\f108";
}
.icon-laptop2:before {
    content: "\f109";
}
.icon-tablet2:before {
    content: "\f10a";
}
.icon-mobile:before {
    content: "\f10b";
}
.icon-mobile-phone:before {
    content: "\f10b";
}
.icon-circle-o:before {
    content: "\f10c";
}
.icon-quote-left:before {
    content: "\f10d";
}
.icon-quote-right:before {
    content: "\f10e";
}
.icon-spinner:before {
    content: "\f110";
}
.icon-circle:before {
    content: "\f111";
}
.icon-mail-reply:before {
    content: "\f112";
}
.icon-reply2:before {
    content: "\f112";
}
.icon-github-alt:before {
    content: "\f113";
}
.icon-folder-o:before {
    content: "\f114";
}
.icon-folder-open-o:before {
    content: "\f115";
}
.icon-smile-o:before {
    content: "\f118";
}
.icon-frown-o:before {
    content: "\f119";
}
.icon-meh-o:before {
    content: "\f11a";
}
.icon-gamepad2:before {
    content: "\f11b";
}
.icon-keyboard-o:before {
    content: "\f11c";
}
.icon-flag-o:before {
    content: "\f11d";
}
.icon-flag-checkered:before {
    content: "\f11e";
}
.icon-terminal:before {
    content: "\f120";
}
.icon-code2:before {
    content: "\f121";
}
.icon-mail-reply-all:before {
    content: "\f122";
}
.icon-reply-all:before {
    content: "\f122";
}
.icon-star-half-empty:before {
    content: "\f123";
}
.icon-star-half-full:before {
    content: "\f123";
}
.icon-star-half-o:before {
    content: "\f123";
}
.icon-location-arrow:before {
    content: "\f124";
}
.icon-crop2:before {
    content: "\f125";
}
.icon-code-fork:before {
    content: "\f126";
}
.icon-chain-broken:before {
    content: "\f127";
}
.icon-unlink:before {
    content: "\f127";
}
.icon-question:before {
    content: "\f128";
}
.icon-info2:before {
    content: "\f129";
}
.icon-exclamation:before {
    content: "\f12a";
}
.icon-superscript:before {
    content: "\f12b";
}
.icon-subscript:before {
    content: "\f12c";
}
.icon-eraser:before {
    content: "\f12d";
}
.icon-puzzle-piece:before {
    content: "\f12e";
}
.icon-microphone:before {
    content: "\f130";
}
.icon-microphone-slash:before {
    content: "\f131";
}
.icon-shield:before {
    content: "\f132";
}
.icon-calendar-o:before {
    content: "\f133";
}
.icon-fire-extinguisher:before {
    content: "\f134";
}
.icon-rocket:before {
    content: "\f135";
}
.icon-maxcdn:before {
    content: "\f136";
}
.icon-chevron-circle-left:before {
    content: "\f137";
}
.icon-chevron-circle-right:before {
    content: "\f138";
}
.icon-chevron-circle-up:before {
    content: "\f139";
}
.icon-chevron-circle-down:before {
    content: "\f13a";
}
.icon-html5:before {
    content: "\f13b";
}
.icon-css3:before {
    content: "\f13c";
}
.icon-anchor:before {
    content: "\f13d";
}
.icon-unlock-alt:before {
    content: "\f13e";
}
.icon-bullseye:before {
    content: "\f140";
}
.icon-ellipsis-h:before {
    content: "\f141";
}
.icon-ellipsis-v:before {
    content: "\f142";
}
.icon-rss-square:before {
    content: "\f143";
}
.icon-play-circle:before {
    content: "\f144";
}
.icon-ticket:before {
    content: "\f145";
}
.icon-minus-square:before {
    content: "\f146";
}
.icon-minus-square-o:before {
    content: "\f147";
}
.icon-level-up:before {
    content: "\f148";
}
.icon-level-down:before {
    content: "\f149";
}
.icon-check-square:before {
    content: "\f14a";
}
.icon-pencil-square:before {
    content: "\f14b";
}
.icon-external-link-square:before {
    content: "\f14c";
}
.icon-share-square:before {
    content: "\f14d";
}
.icon-compass:before {
    content: "\f14e";
}
.icon-caret-square-o-down:before {
    content: "\f150";
}
.icon-toggle-down:before {
    content: "\f150";
}
.icon-caret-square-o-up:before {
    content: "\f151";
}
.icon-toggle-up:before {
    content: "\f151";
}
.icon-caret-square-o-right:before {
    content: "\f152";
}
.icon-toggle-right:before {
    content: "\f152";
}
.icon-eur:before {
    content: "\f153";
}
.icon-euro:before {
    content: "\f153";
}
.icon-gbp:before {
    content: "\f154";
}
.icon-dollar:before {
    content: "\f155";
}
.icon-usd:before {
    content: "\f155";
}
.icon-inr:before {
    content: "\f156";
}
.icon-rupee:before {
    content: "\f156";
}
.icon-cny:before {
    content: "\f157";
}
.icon-jpy:before {
    content: "\f157";
}
.icon-rmb:before {
    content: "\f157";
}
.icon-yen:before {
    content: "\f157";
}
.icon-rouble:before {
    content: "\f158";
}
.icon-rub:before {
    content: "\f158";
}
.icon-ruble:before {
    content: "\f158";
}
.icon-krw:before {
    content: "\f159";
}
.icon-won:before {
    content: "\f159";
}
.icon-bitcoin:before {
    content: "\f15a";
}
.icon-btc:before {
    content: "\f15a";
}
.icon-file:before {
    content: "\f15b";
}
.icon-file-text:before {
    content: "\f15c";
}
.icon-sort-alpha-asc:before {
    content: "\f15d";
}
.icon-sort-alpha-desc:before {
    content: "\f15e";
}
.icon-sort-amount-asc:before {
    content: "\f160";
}
.icon-sort-amount-desc:before {
    content: "\f161";
}
.icon-sort-numeric-asc:before {
    content: "\f162";
}
.icon-sort-numeric-desc:before {
    content: "\f163";
}
.icon-thumbs-up:before {
    content: "\f164";
}
.icon-thumbs-down:before {
    content: "\f165";
}
.icon-youtube-square:before {
    content: "\f166";
}
.icon-youtube:before {
    content: "\f167";
}
.icon-xing:before {
    content: "\f168";
}
.icon-xing-square:before {
    content: "\f169";
}
.icon-youtube-play:before {
    content: "\f16a";
}
.icon-dropbox:before {
    content: "\f16b";
}
.icon-stack-overflow:before {
    content: "\f16c";
}
.icon-instagram:before {
    content: "\f16d";
}
.icon-flickr:before {
    content: "\f16e";
}
.icon-adn:before {
    content: "\f170";
}
.icon-bitbucket:before {
    content: "\f171";
}
.icon-bitbucket-square:before {
    content: "\f172";
}
.icon-tumblr:before {
    content: "\f173";
}
.icon-tumblr-square:before {
    content: "\f174";
}
.icon-long-arrow-down:before {
    content: "\f175";
}
.icon-long-arrow-up:before {
    content: "\f176";
}
.icon-long-arrow-left:before {
    content: "\f177";
}
.icon-long-arrow-right:before {
    content: "\f178";
}
.icon-apple:before {
    content: "\f179";
}
.icon-windows:before {
    content: "\f17a";
}
.icon-android2:before {
    content: "\f17b";
}
.icon-linux:before {
    content: "\f17c";
}
.icon-dribbble:before {
    content: "\f17d";
}
.icon-skype:before {
    content: "\f17e";
}
.icon-foursquare:before {
    content: "\f180";
}
.icon-trello:before {
    content: "\f181";
}
.icon-female:before {
    content: "\f182";
}
.icon-male:before {
    content: "\f183";
}
.icon-gittip:before {
    content: "\f184";
}
.icon-gratipay:before {
    content: "\f184";
}
.icon-sun-o:before {
    content: "\f185";
}
.icon-moon-o:before {
    content: "\f186";
}
.icon-archive2:before {
    content: "\f187";
}
.icon-bug:before {
    content: "\f188";
}
.icon-vk:before {
    content: "\f189";
}
.icon-weibo:before {
    content: "\f18a";
}
.icon-renren:before {
    content: "\f18b";
}
.icon-pagelines:before {
    content: "\f18c";
}
.icon-stack-exchange:before {
    content: "\f18d";
}
.icon-arrow-circle-o-right:before {
    content: "\f18e";
}
.icon-arrow-circle-o-left:before {
    content: "\f190";
}
.icon-caret-square-o-left:before {
    content: "\f191";
}
.icon-toggle-left:before {
    content: "\f191";
}
.icon-dot-circle-o:before {
    content: "\f192";
}
.icon-wheelchair:before {
    content: "\f193";
}
.icon-vimeo-square:before {
    content: "\f194";
}
.icon-try:before {
    content: "\f195";
}
.icon-turkish-lira:before {
    content: "\f195";
}
.icon-plus-square-o:before {
    content: "\f196";
}
.icon-space-shuttle:before {
    content: "\f197";
}
.icon-slack:before {
    content: "\f198";
}
.icon-envelope-square:before {
    content: "\f199";
}
.icon-wordpress:before {
    content: "\f19a";
}
.icon-openid:before {
    content: "\f19b";
}
.icon-bank:before {
    content: "\f19c";
}
.icon-institution:before {
    content: "\f19c";
}
.icon-university:before {
    content: "\f19c";
}
.icon-graduation-cap:before {
    content: "\f19d";
}
.icon-mortar-board:before {
    content: "\f19d";
}
.icon-yahoo:before {
    content: "\f19e";
}
.icon-google:before {
    content: "\f1a0";
}
.icon-reddit:before {
    content: "\f1a1";
}
.icon-reddit-square:before {
    content: "\f1a2";
}
.icon-stumbleupon-circle:before {
    content: "\f1a3";
}
.icon-stumbleupon:before {
    content: "\f1a4";
}
.icon-delicious:before {
    content: "\f1a5";
}
.icon-digg:before {
    content: "\f1a6";
}
.icon-pied-piper:before {
    content: "\f1a7";
}
.icon-pied-piper-alt:before {
    content: "\f1a8";
}
.icon-drupal:before {
    content: "\f1a9";
}
.icon-joomla:before {
    content: "\f1aa";
}
.icon-language2:before {
    content: "\f1ab";
}
.icon-fax:before {
    content: "\f1ac";
}
.icon-building:before {
    content: "\f1ad";
}
.icon-child:before {
    content: "\f1ae";
}
.icon-paw:before {
    content: "\f1b0";
}
.icon-spoon:before {
    content: "\f1b1";
}
.icon-cube:before {
    content: "\f1b2";
}
.icon-cubes:before {
    content: "\f1b3";
}
.icon-behance:before {
    content: "\f1b4";
}
.icon-behance-square:before {
    content: "\f1b5";
}
.icon-steam:before {
    content: "\f1b6";
}
.icon-steam-square:before {
    content: "\f1b7";
}
.icon-recycle:before {
    content: "\f1b8";
}
.icon-automobile:before {
    content: "\f1b9";
}
.icon-car:before {
    content: "\f1b9";
}
.icon-cab:before {
    content: "\f1ba";
}
.icon-taxi:before {
    content: "\f1ba";
}
.icon-tree:before {
    content: "\f1bb";
}
.icon-spotify:before {
    content: "\f1bc";
}
.icon-deviantart:before {
    content: "\f1bd";
}
.icon-soundcloud:before {
    content: "\f1be";
}
.icon-database:before {
    content: "\f1c0";
}
.icon-file-pdf-o:before {
    content: "\f1c1";
}
.icon-file-word-o:before {
    content: "\f1c2";
}
.icon-file-excel-o:before {
    content: "\f1c3";
}
.icon-file-powerpoint-o:before {
    content: "\f1c4";
}
.icon-file-image-o:before {
    content: "\f1c5";
}
.icon-file-photo-o:before {
    content: "\f1c5";
}
.icon-file-picture-o:before {
    content: "\f1c5";
}
.icon-file-archive-o:before {
    content: "\f1c6";
}
.icon-file-zip-o:before {
    content: "\f1c6";
}
.icon-file-audio-o:before {
    content: "\f1c7";
}
.icon-file-sound-o:before {
    content: "\f1c7";
}
.icon-file-movie-o:before {
    content: "\f1c8";
}
.icon-file-video-o:before {
    content: "\f1c8";
}
.icon-file-code-o:before {
    content: "\f1c9";
}
.icon-vine:before {
    content: "\f1ca";
}
.icon-codepen:before {
    content: "\f1cb";
}
.icon-jsfiddle:before {
    content: "\f1cc";
}
.icon-life-bouy:before {
    content: "\f1cd";
}
.icon-life-buoy:before {
    content: "\f1cd";
}
.icon-life-ring:before {
    content: "\f1cd";
}
.icon-life-saver:before {
    content: "\f1cd";
}
.icon-support:before {
    content: "\f1cd";
}
.icon-circle-o-notch:before {
    content: "\f1ce";
}
.icon-ra:before {
    content: "\f1d0";
}
.icon-rebel:before {
    content: "\f1d0";
}
.icon-empire:before {
    content: "\f1d1";
}
.icon-ge:before {
    content: "\f1d1";
}
.icon-git-square:before {
    content: "\f1d2";
}
.icon-git:before {
    content: "\f1d3";
}
.icon-hacker-news:before {
    content: "\f1d4";
}
.icon-y-combinator-square:before {
    content: "\f1d4";
}
.icon-yc-square:before {
    content: "\f1d4";
}
.icon-tencent-weibo:before {
    content: "\f1d5";
}
.icon-qq:before {
    content: "\f1d6";
}
.icon-wechat:before {
    content: "\f1d7";
}
.icon-weixin:before {
    content: "\f1d7";
}
.icon-paper-plane:before {
    content: "\f1d8";
}
.icon-send2:before {
    content: "\f1d8";
}
.icon-paper-plane-o:before {
    content: "\f1d9";
}
.icon-send-o:before {
    content: "\f1d9";
}
.icon-history2:before {
    content: "\f1da";
}
.icon-circle-thin:before {
    content: "\f1db";
}
.icon-header:before {
    content: "\f1dc";
}
.icon-paragraph:before {
    content: "\f1dd";
}
.icon-sliders:before {
    content: "\f1de";
}
.icon-share-alt:before {
    content: "\f1e0";
}
.icon-share-alt-square:before {
    content: "\f1e1";
}
.icon-bomb:before {
    content: "\f1e2";
}
.icon-futbol-o:before {
    content: "\f1e3";
}
.icon-soccer-ball-o:before {
    content: "\f1e3";
}
.icon-tty:before {
    content: "\f1e4";
}
.icon-binoculars:before {
    content: "\f1e5";
}
.icon-plug:before {
    content: "\f1e6";
}
.icon-slideshare:before {
    content: "\f1e7";
}
.icon-twitch:before {
    content: "\f1e8";
}
.icon-yelp:before {
    content: "\f1e9";
}
.icon-newspaper-o:before {
    content: "\f1ea";
}
.icon-wifi2:before {
    content: "\f1eb";
}
.icon-calculator:before {
    content: "\f1ec";
}
.icon-paypal:before {
    content: "\f1ed";
}
.icon-google-wallet:before {
    content: "\f1ee";
}
.icon-cc-visa:before {
    content: "\f1f0";
}
.icon-cc-mastercard:before {
    content: "\f1f1";
}
.icon-cc-discover:before {
    content: "\f1f2";
}
.icon-cc-amex:before {
    content: "\f1f3";
}
.icon-cc-paypal:before {
    content: "\f1f4";
}
.icon-cc-stripe:before {
    content: "\f1f5";
}
.icon-bell-slash:before {
    content: "\f1f6";
}
.icon-bell-slash-o:before {
    content: "\f1f7";
}
.icon-trash:before {
    content: "\f1f8";
}
.icon-copyright2:before {
    content: "\f1f9";
}
.icon-at:before {
    content: "\f1fa";
}
.icon-eyedropper:before {
    content: "\f1fb";
}
.icon-paint-brush:before {
    content: "\f1fc";
}
.icon-birthday-cake:before {
    content: "\f1fd";
}
.icon-area-chart:before {
    content: "\f1fe";
}
.icon-pie-chart:before {
    content: "\f200";
}
.icon-line-chart:before {
    content: "\f201";
}
.icon-lastfm:before {
    content: "\f202";
}
.icon-lastfm-square:before {
    content: "\f203";
}
.icon-toggle-off:before {
    content: "\f204";
}
.icon-toggle-on:before {
    content: "\f205";
}
.icon-bicycle:before {
    content: "\f206";
}
.icon-bus:before {
    content: "\f207";
}
.icon-ioxhost:before {
    content: "\f208";
}
.icon-angellist:before {
    content: "\f209";
}
.icon-cc:before {
    content: "\f20a";
}
.icon-ils:before {
    content: "\f20b";
}
.icon-shekel:before {
    content: "\f20b";
}
.icon-sheqel:before {
    content: "\f20b";
}
.icon-meanpath:before {
    content: "\f20c";
}
.icon-buysellads:before {
    content: "\f20d";
}
.icon-connectdevelop:before {
    content: "\f20e";
}
.icon-dashcube:before {
    content: "\f210";
}
.icon-forumbee:before {
    content: "\f211";
}
.icon-leanpub:before {
    content: "\f212";
}
.icon-sellsy:before {
    content: "\f213";
}
.icon-shirtsinbulk:before {
    content: "\f214";
}
.icon-simplybuilt:before {
    content: "\f215";
}
.icon-skyatlas:before {
    content: "\f216";
}
.icon-cart-plus:before {
    content: "\f217";
}
.icon-cart-arrow-down:before {
    content: "\f218";
}
.icon-diamond:before {
    content: "\f219";
}
.icon-ship:before {
    content: "\f21a";
}
.icon-user-secret:before {
    content: "\f21b";
}
.icon-motorcycle2:before {
    content: "\f21c";
}
.icon-street-view:before {
    content: "\f21d";
}
.icon-heartbeat:before {
    content: "\f21e";
}
.icon-venus:before {
    content: "\f221";
}
.icon-mars:before {
    content: "\f222";
}
.icon-mercury:before {
    content: "\f223";
}
.icon-intersex:before {
    content: "\f224";
}
.icon-transgender:before {
    content: "\f224";
}
.icon-transgender-alt:before {
    content: "\f225";
}
.icon-venus-double:before {
    content: "\f226";
}
.icon-mars-double:before {
    content: "\f227";
}
.icon-venus-mars:before {
    content: "\f228";
}
.icon-mars-stroke:before {
    content: "\f229";
}
.icon-mars-stroke-v:before {
    content: "\f22a";
}
.icon-mars-stroke-h:before {
    content: "\f22b";
}
.icon-neuter:before {
    content: "\f22c";
}
.icon-genderless:before {
    content: "\f22d";
}
.icon-facebook-official:before {
    content: "\f230";
}
.icon-pinterest-p:before {
    content: "\f231";
}
.icon-whatsapp:before {
    content: "\f232";
}
.icon-server:before {
    content: "\f233";
}
.icon-user-plus:before {
    content: "\f234";
}
.icon-user-times:before {
    content: "\f235";
}
.icon-bed:before {
    content: "\f236";
}
.icon-hotel2:before {
    content: "\f236";
}
.icon-viacoin:before {
    content: "\f237";
}
.icon-train:before {
    content: "\f238";
}
.icon-subway:before {
    content: "\f239";
}
.icon-medium:before {
    content: "\f23a";
}
.icon-y-combinator:before {
    content: "\f23b";
}
.icon-yc:before {
    content: "\f23b";
}
.icon-optin-monster:before {
    content: "\f23c";
}
.icon-opencart:before {
    content: "\f23d";
}
.icon-expeditedssl:before {
    content: "\f23e";
}
.icon-battery-4:before {
    content: "\f240";
}
.icon-battery-full:before {
    content: "\f240";
}
.icon-battery-3:before {
    content: "\f241";
}
.icon-battery-three-quarters:before {
    content: "\f241";
}
.icon-battery-2:before {
    content: "\f242";
}
.icon-battery-half:before {
    content: "\f242";
}
.icon-battery-1:before {
    content: "\f243";
}
.icon-battery-quarter:before {
    content: "\f243";
}
.icon-battery-0:before {
    content: "\f244";
}
.icon-battery-empty:before {
    content: "\f244";
}
.icon-mouse-pointer:before {
    content: "\f245";
}
.icon-i-cursor:before {
    content: "\f246";
}
.icon-object-group:before {
    content: "\f247";
}
.icon-object-ungroup:before {
    content: "\f248";
}
.icon-sticky-note:before {
    content: "\f249";
}
.icon-sticky-note-o:before {
    content: "\f24a";
}
.icon-cc-jcb:before {
    content: "\f24b";
}
.icon-cc-diners-club:before {
    content: "\f24c";
}
.icon-clone:before {
    content: "\f24d";
}
.icon-balance-scale:before {
    content: "\f24e";
}
.icon-hourglass-o:before {
    content: "\f250";
}
.icon-hourglass-1:before {
    content: "\f251";
}
.icon-hourglass-start:before {
    content: "\f251";
}
.icon-hourglass-2:before {
    content: "\f252";
}
.icon-hourglass-half:before {
    content: "\f252";
}
.icon-hourglass-3:before {
    content: "\f253";
}
.icon-hourglass-end:before {
    content: "\f253";
}
.icon-hourglass:before {
    content: "\f254";
}
.icon-hand-grab-o:before {
    content: "\f255";
}
.icon-hand-rock-o:before {
    content: "\f255";
}
.icon-hand-paper-o:before {
    content: "\f256";
}
.icon-hand-stop-o:before {
    content: "\f256";
}
.icon-hand-scissors-o:before {
    content: "\f257";
}
.icon-hand-lizard-o:before {
    content: "\f258";
}
.icon-hand-spock-o:before {
    content: "\f259";
}
.icon-hand-pointer-o:before {
    content: "\f25a";
}
.icon-hand-peace-o:before {
    content: "\f25b";
}
.icon-trademark:before {
    content: "\f25c";
}
.icon-registered:before {
    content: "\f25d";
}
.icon-creative-commons:before {
    content: "\f25e";
}
.icon-gg:before {
    content: "\f260";
}
.icon-gg-circle:before {
    content: "\f261";
}
.icon-tripadvisor:before {
    content: "\f262";
}
.icon-odnoklassniki:before {
    content: "\f263";
}
.icon-odnoklassniki-square:before {
    content: "\f264";
}
.icon-get-pocket:before {
    content: "\f265";
}
.icon-wikipedia-w:before {
    content: "\f266";
}
.icon-safari:before {
    content: "\f267";
}
.icon-chrome:before {
    content: "\f268";
}
.icon-firefox:before {
    content: "\f269";
}
.icon-opera:before {
    content: "\f26a";
}
.icon-internet-explorer:before {
    content: "\f26b";
}
.icon-television:before {
    content: "\f26c";
}
.icon-tv2:before {
    content: "\f26c";
}
.icon-contao:before {
    content: "\f26d";
}
.icon-500px:before {
    content: "\f26e";
}
.icon-amazon:before {
    content: "\f270";
}
.icon-calendar-plus-o:before {
    content: "\f271";
}
.icon-calendar-minus-o:before {
    content: "\f272";
}
.icon-calendar-times-o:before {
    content: "\f273";
}
.icon-calendar-check-o:before {
    content: "\f274";
}
.icon-industry:before {
    content: "\f275";
}
.icon-map-pin:before {
    content: "\f276";
}
.icon-map-signs:before {
    content: "\f277";
}
.icon-map-o:before {
    content: "\f278";
}
.icon-map2:before {
    content: "\f279";
}
.icon-commenting:before {
    content: "\f27a";
}
.icon-commenting-o:before {
    content: "\f27b";
}
.icon-houzz:before {
    content: "\f27c";
}
.icon-vimeo:before {
    content: "\f27d";
}
.icon-black-tie:before {
    content: "\f27e";
}
.icon-fonticons:before {
    content: "\f280";
}
.icon-reddit-alien:before {
    content: "\f281";
}
.icon-edge:before {
    content: "\f282";
}
.icon-credit-card-alt:before {
    content: "\f283";
}
.icon-codiepie:before {
    content: "\f284";
}
.icon-modx:before {
    content: "\f285";
}
.icon-fort-awesome:before {
    content: "\f286";
}
.icon-usb2:before {
    content: "\f287";
}
.icon-product-hunt:before {
    content: "\f288";
}
.icon-mixcloud:before {
    content: "\f289";
}
.icon-scribd:before {
    content: "\f28a";
}
.icon-pause-circle:before {
    content: "\f28b";
}
.icon-pause-circle-o:before {
    content: "\f28c";
}
.icon-stop-circle:before {
    content: "\f28d";
}
.icon-stop-circle-o:before {
    content: "\f28e";
}
.icon-shopping-bag:before {
    content: "\f290";
}
.icon-shopping-basket:before {
    content: "\f291";
}
.icon-hashtag:before {
    content: "\f292";
}
.icon-bluetooth2:before {
    content: "\f293";
}
.icon-bluetooth-b:before {
    content: "\f294";
}
.icon-percent:before {
    content: "\f295";
}
.icon-add-to-list:before {
    content: "\e907";
}
.icon-classic-computer:before {
    content: "\e910";
}
.icon-controller-fast-backward:before {
    content: "\e926";
}
.icon-creative-commons-attribution:before {
    content: "\e927";
}
.icon-creative-commons-noderivs:before {
    content: "\e928";
}
.icon-creative-commons-noncommercial-eu:before {
    content: "\e929";
}
.icon-creative-commons-noncommercial-us:before {
    content: "\e92a";
}
.icon-creative-commons-public-domain:before {
    content: "\e92b";
}
.icon-creative-commons-remix:before {
    content: "\e92c";
}
.icon-creative-commons-share:before {
    content: "\e92d";
}
.icon-creative-commons-sharealike:before {
    content: "\e92e";
}
.icon-creative-commons2:before {
    content: "\e92f";
}
.icon-document-landscape:before {
    content: "\e930";
}
.icon-remove-user:before {
    content: "\e931";
}
.icon-warning4:before {
    content: "\e932";
}
.icon-arrow-bold-down:before {
    content: "\e933";
}
.icon-arrow-bold-left:before {
    content: "\e934";
}
.icon-arrow-bold-right:before {
    content: "\e935";
}
.icon-arrow-bold-up:before {
    content: "\e936";
}
.icon-arrow-down3:before {
    content: "\e937";
}
.icon-arrow-left3:before {
    content: "\e938";
}
.icon-arrow-long-down:before {
    content: "\e939";
}
.icon-arrow-long-left:before {
    content: "\e93a";
}
.icon-arrow-long-right:before {
    content: "\e93b";
}
.icon-arrow-long-up:before {
    content: "\e93c";
}
.icon-arrow-right3:before {
    content: "\e93d";
}
.icon-arrow-up3:before {
    content: "\e93e";
}
.icon-arrow-with-circle-down:before {
    content: "\e93f";
}
.icon-arrow-with-circle-left:before {
    content: "\e940";
}
.icon-arrow-with-circle-right:before {
    content: "\e941";
}
.icon-arrow-with-circle-up:before {
    content: "\e942";
}
.icon-bookmark4:before {
    content: "\e943";
}
.icon-bookmarks2:before {
    content: "\e944";
}
.icon-chevron-down2:before {
    content: "\e945";
}
.icon-chevron-left2:before {
    content: "\e946";
}
.icon-chevron-right2:before {
    content: "\e947";
}
.icon-chevron-small-down:before {
    content: "\e948";
}
.icon-chevron-small-left:before {
    content: "\e949";
}
.icon-chevron-small-right:before {
    content: "\e94a";
}
.icon-chevron-small-up:before {
    content: "\e94b";
}
.icon-chevron-thin-down:before {
    content: "\e94c";
}
.icon-chevron-thin-left:before {
    content: "\e94d";
}
.icon-chevron-thin-right:before {
    content: "\e94e";
}
.icon-chevron-thin-up:before {
    content: "\e94f";
}
.icon-chevron-up2:before {
    content: "\e950";
}
.icon-chevron-with-circle-down:before {
    content: "\e951";
}
.icon-chevron-with-circle-left:before {
    content: "\e952";
}
.icon-chevron-with-circle-right:before {
    content: "\e953";
}
.icon-chevron-with-circle-up:before {
    content: "\e954";
}
.icon-cloud5:before {
    content: "\e955";
}
.icon-controller-fast-forward:before {
    content: "\e956";
}
.icon-controller-jump-to-start:before {
    content: "\e957";
}
.icon-controller-next:before {
    content: "\e958";
}
.icon-controller-paus:before {
    content: "\e959";
}
.icon-controller-play:before {
    content: "\e95a";
}
.icon-controller-record:before {
    content: "\e95b";
}
.icon-controller-stop:before {
    content: "\e95c";
}
.icon-controller-volume:before {
    content: "\e95d";
}
.icon-dot-single:before {
    content: "\e95e";
}
.icon-dots-three-horizontal:before {
    content: "\e95f";
}
.icon-dots-three-vertical:before {
    content: "\e960";
}
.icon-dots-two-horizontal:before {
    content: "\e961";
}
.icon-dots-two-vertical:before {
    content: "\e962";
}
.icon-download5:before {
    content: "\e963";
}
.icon-emoji-flirt:before {
    content: "\e964";
}
.icon-flow-branch:before {
    content: "\e965";
}
.icon-flow-cascade:before {
    content: "\e966";
}
.icon-flow-line:before {
    content: "\e967";
}
.icon-flow-parallel:before {
    content: "\e968";
}
.icon-flow-tree:before {
    content: "\e969";
}
.icon-install:before {
    content: "\e96a";
}
.icon-layers3:before {
    content: "\e96b";
}
.icon-open-book:before {
    content: "\e96c";
}
.icon-resize-100:before {
    content: "\e96d";
}
.icon-resize-full-screen:before {
    content: "\e96e";
}
.icon-save3:before {
    content: "\e96f";
}
.icon-select-arrows:before {
    content: "\e970";
}
.icon-sound-mute:before {
    content: "\e971";
}
.icon-sound:before {
    content: "\e972";
}
.icon-trash2:before {
    content: "\e973";
}
.icon-triangle-down:before {
    content: "\e974";
}
.icon-triangle-left:before {
    content: "\e975";
}
.icon-triangle-right:before {
    content: "\e976";
}
.icon-triangle-up:before {
    content: "\e977";
}
.icon-uninstall:before {
    content: "\e978";
}
.icon-upload-to-cloud:before {
    content: "\e979";
}
.icon-upload5:before {
    content: "\e97a";
}
.icon-add-user:before {
    content: "\e97b";
}
.icon-address:before {
    content: "\e97c";
}
.icon-adjust3:before {
    content: "\e97d";
}
.icon-air:before {
    content: "\e97e";
}
.icon-aircraft-landing:before {
    content: "\e97f";
}
.icon-aircraft-take-off:before {
    content: "\e980";
}
.icon-aircraft:before {
    content: "\e981";
}
.icon-align-bottom:before {
    content: "\e982";
}
.icon-align-horizontal-middle:before {
    content: "\e983";
}
.icon-align-left2:before {
    content: "\e984";
}
.icon-align-right2:before {
    content: "\e985";
}
.icon-align-top:before {
    content: "\e986";
}
.icon-align-vertical-middle:before {
    content: "\e987";
}
.icon-archive3:before {
    content: "\e988";
}
.icon-area-graph:before {
    content: "\e989";
}
.icon-attachment4:before {
    content: "\e98a";
}
.icon-awareness-ribbon:before {
    content: "\e98b";
}
.icon-back-in-time:before {
    content: "\e98c";
}
.icon-back:before {
    content: "\e98d";
}
.icon-bar-graph:before {
    content: "\e98e";
}
.icon-battery:before {
    content: "\e98f";
}
.icon-beamed-note:before {
    content: "\e990";
}
.icon-bell3:before {
    content: "\e991";
}
.icon-blackboard:before {
    content: "\e992";
}
.icon-block2:before {
    content: "\e993";
}
.icon-book4:before {
    content: "\e994";
}
.icon-bowl:before {
    content: "\e995";
}
.icon-box:before {
    content: "\e996";
}
.icon-briefcase4:before {
    content: "\e997";
}
.icon-browser2:before {
    content: "\e998";
}
.icon-brush2:before {
    content: "\e999";
}
.icon-bucket:before {
    content: "\e99a";
}
.icon-cake2:before {
    content: "\e99b";
}
.icon-calculator3:before {
    content: "\e99c";
}
.icon-calendar4:before {
    content: "\e99d";
}
.icon-camera5:before {
    content: "\e99e";
}
.icon-ccw:before {
    content: "\e99f";
}
.icon-chat3:before {
    content: "\e9a0";
}
.icon-check3:before {
    content: "\e9a1";
}
.icon-circle-with-cross:before {
    content: "\e9a2";
}
.icon-circle-with-minus:before {
    content: "\e9a3";
}
.icon-circle-with-plus:before {
    content: "\e9a4";
}
.icon-circle2:before {
    content: "\e9a5";
}
.icon-circular-graph:before {
    content: "\e9a6";
}
.icon-clapperboard:before {
    content: "\e9a7";
}
.icon-clipboard4:before {
    content: "\e9a8";
}
.icon-clock4:before {
    content: "\e9a9";
}
.icon-code3:before {
    content: "\e9aa";
}
.icon-cog3:before {
    content: "\e9ab";
}
.icon-colours:before {
    content: "\e9ac";
}
.icon-compass4:before {
    content: "\e9ad";
}
.icon-copy3:before {
    content: "\e9ae";
}
.icon-credit-card3:before {
    content: "\e9af";
}
.icon-credit:before {
    content: "\e9b0";
}
.icon-cross2:before {
    content: "\e9b1";
}
.icon-cup:before {
    content: "\e9b2";
}
.icon-cw:before {
    content: "\e9b3";
}
.icon-cycle:before {
    content: "\e9b4";
}
.icon-database3:before {
    content: "\e9b5";
}
.icon-dial-pad:before {
    content: "\e9b6";
}
.icon-direction:before {
    content: "\e9b7";
}
.icon-document2:before {
    content: "\e9b8";
}
.icon-documents2:before {
    content: "\e9b9";
}
.icon-drink:before {
    content: "\e9ba";
}
.icon-drive2:before {
    content: "\e9bb";
}
.icon-drop:before {
    content: "\e9bc";
}
.icon-edit4:before {
    content: "\e9bd";
}
.icon-email2:before {
    content: "\e9be";
}
.icon-emoji-happy:before {
    content: "\e9bf";
}
.icon-emoji-neutral:before {
    content: "\e9c0";
}
.icon-emoji-sad:before {
    content: "\e9c1";
}
.icon-erase:before {
    content: "\e9c2";
}
.icon-eraser2:before {
    content: "\e9c3";
}
.icon-export:before {
    content: "\e9c4";
}
.icon-eye3:before {
    content: "\e9c5";
}
.icon-feather:before {
    content: "\e9c6";
}
.icon-flag5:before {
    content: "\e9c7";
}
.icon-flash2:before {
    content: "\e9c8";
}
.icon-flashlight:before {
    content: "\e9c9";
}
.icon-flat-brush:before {
    content: "\e9ca";
}
.icon-folder-images:before {
    content: "\e9cb";
}
.icon-folder-music:before {
    content: "\e9cc";
}
.icon-folder-video:before {
    content: "\e9cd";
}
.icon-folder4:before {
    content: "\e9ce";
}
.icon-forward4:before {
    content: "\e9cf";
}
.icon-funnel:before {
    content: "\e9d0";
}
.icon-game-controller:before {
    content: "\e9d1";
}
.icon-gauge:before {
    content: "\e9d2";
}
.icon-globe3:before {
    content: "\e9d3";
}
.icon-graduation-cap2:before {
    content: "\e9d4";
}
.icon-grid2:before {
    content: "\e9d5";
}
.icon-hair-cross:before {
    content: "\e9d6";
}
.icon-hand:before {
    content: "\e9d7";
}
.icon-heart-outlined:before {
    content: "\e9d8";
}
.icon-heart4:before {
    content: "\e9d9";
}
.icon-help-with-circle:before {
    content: "\e9da";
}
.icon-help2:before {
    content: "\e9db";
}
.icon-home4:before {
    content: "\e9dc";
}
.icon-hour-glass2:before {
    content: "\e9dd";
}
.icon-image-inverted:before {
    content: "\e9de";
}
.icon-image4:before {
    content: "\e9df";
}
.icon-images2:before {
    content: "\e9e0";
}
.icon-inbox3:before {
    content: "\e9e1";
}
.icon-infinity:before {
    content: "\e9e2";
}
.icon-info-with-circle:before {
    content: "\e9e3";
}
.icon-info4:before {
    content: "\e9e4";
}
.icon-key4:before {
    content: "\e9e5";
}
.icon-keyboard3:before {
    content: "\e9e6";
}
.icon-lab-flask:before {
    content: "\e9e7";
}
.icon-landline:before {
    content: "\e9e8";
}
.icon-language3:before {
    content: "\e9e9";
}
.icon-laptop5:before {
    content: "\e9ea";
}
.icon-leaf3:before {
    content: "\e9eb";
}
.icon-level-down2:before {
    content: "\e9ec";
}
.icon-level-up2:before {
    content: "\e9ed";
}
.icon-lifebuoy2:before {
    content: "\e9ee";
}
.icon-light-bulb:before {
    content: "\e9ef";
}
.icon-light-down:before {
    content: "\e9f0";
}
.icon-light-up:before {
    content: "\e9f1";
}
.icon-line-graph:before {
    content: "\e9f2";
}
.icon-link4:before {
    content: "\e9f3";
}
.icon-list4:before {
    content: "\e9f4";
}
.icon-location-pin:before {
    content: "\e9f5";
}
.icon-location3:before {
    content: "\e9f6";
}
.icon-lock-open:before {
    content: "\e9f7";
}
.icon-lock5:before {
    content: "\e9f8";
}
.icon-log-out:before {
    content: "\e9f9";
}
.icon-login:before {
    content: "\e9fa";
}
.icon-loop3:before {
    content: "\e9fb";
}
.icon-magnet3:before {
    content: "\e9fc";
}
.icon-magnifying-glass2:before {
    content: "\e9fd";
}
.icon-mail5:before {
    content: "\e9fe";
}
.icon-man2:before {
    content: "\e9ff";
}
.icon-map5:before {
    content: "\ea00";
}
.icon-mask:before {
    content: "\ea01";
}
.icon-medal:before {
    content: "\ea02";
}
.icon-megaphone2:before {
    content: "\ea03";
}
.icon-menu5:before {
    content: "\ea04";
}
.icon-message2:before {
    content: "\ea05";
}
.icon-mic4:before {
    content: "\ea06";
}
.icon-minus3:before {
    content: "\ea07";
}
.icon-mobile4:before {
    content: "\ea08";
}
.icon-modern-mic:before {
    content: "\ea09";
}
.icon-moon:before {
    content: "\ea0a";
}
.icon-mouse2:before {
    content: "\ea0b";
}
.icon-music3:before {
    content: "\ea0c";
}
.icon-network:before {
    content: "\ea0d";
}
.icon-new-message:before {
    content: "\ea0e";
}
.icon-new:before {
    content: "\ea0f";
}
.icon-news:before {
    content: "\ea10";
}
.icon-note:before {
    content: "\ea11";
}
.icon-notification2:before {
    content: "\ea12";
}
.icon-old-mobile:before {
    content: "\ea13";
}
.icon-old-phone:before {
    content: "\ea14";
}
.icon-palette2:before {
    content: "\ea15";
}
.icon-paper-plane2:before {
    content: "\ea16";
}
.icon-pencil4:before {
    content: "\ea17";
}
.icon-phone5:before {
    content: "\ea18";
}
.icon-pie-chart3:before {
    content: "\ea19";
}
.icon-pin:before {
    content: "\ea1a";
}
.icon-plus3:before {
    content: "\ea1b";
}
.icon-popup:before {
    content: "\ea1c";
}
.icon-power-plug:before {
    content: "\ea1d";
}
.icon-price-ribbon:before {
    content: "\ea1e";
}
.icon-price-tag2:before {
    content: "\ea1f";
}
.icon-print3:before {
    content: "\ea20";
}
.icon-progress-empty:before {
    content: "\ea21";
}
.icon-progress-full:before {
    content: "\ea22";
}
.icon-progress-one:before {
    content: "\ea23";
}
.icon-progress-two:before {
    content: "\ea24";
}
.icon-publish2:before {
    content: "\ea25";
}
.icon-quote2:before {
    content: "\ea26";
}
.icon-radio2:before {
    content: "\ea27";
}
.icon-reply-all2:before {
    content: "\ea28";
}
.icon-reply4:before {
    content: "\ea29";
}
.icon-retweet2:before {
    content: "\ea2a";
}
.icon-rocket3:before {
    content: "\ea2b";
}
.icon-round-brush:before {
    content: "\ea2c";
}
.icon-rss4:before {
    content: "\ea2d";
}
.icon-ruler:before {
    content: "\ea2e";
}
.icon-scissors4:before {
    content: "\ea2f";
}
.icon-share-alternitive:before {
    content: "\ea30";
}
.icon-share4:before {
    content: "\ea31";
}
.icon-shareable:before {
    content: "\ea32";
}
.icon-shield4:before {
    content: "\ea33";
}
.icon-shop2:before {
    content: "\ea34";
}
.icon-shopping-bag2:before {
    content: "\ea35";
}
.icon-shopping-basket2:before {
    content: "\ea36";
}
.icon-shopping-cart2:before {
    content: "\ea37";
}
.icon-shuffle3:before {
    content: "\ea38";
}
.icon-signal2:before {
    content: "\ea39";
}
.icon-sound-mix:before {
    content: "\ea3a";
}
.icon-sports-club:before {
    content: "\ea3b";
}
.icon-spreadsheet:before {
    content: "\ea3c";
}
.icon-squared-cross:before {
    content: "\ea3d";
}
.icon-squared-minus:before {
    content: "\ea3e";
}
.icon-squared-plus:before {
    content: "\ea3f";
}
.icon-star-outlined:before {
    content: "\ea40";
}
.icon-star3:before {
    content: "\ea41";
}
.icon-stopwatch2:before {
    content: "\ea42";
}
.icon-suitcase2:before {
    content: "\ea43";
}
.icon-swap:before {
    content: "\ea44";
}
.icon-sweden:before {
    content: "\ea45";
}
.icon-switch2:before {
    content: "\ea46";
}
.icon-tablet5:before {
    content: "\ea47";
}
.icon-tag2:before {
    content: "\ea48";
}
.icon-text-document-inverted:before {
    content: "\ea49";
}
.icon-text-document:before {
    content: "\ea4a";
}
.icon-text:before {
    content: "\ea4b";
}
.icon-thermometer:before {
    content: "\ea4c";
}
.icon-thumbs-down2:before {
    content: "\ea4d";
}
.icon-thumbs-up2:before {
    content: "\ea4e";
}
.icon-thunder-cloud:before {
    content: "\ea4f";
}
.icon-ticket3:before {
    content: "\ea50";
}
.icon-time-slot:before {
    content: "\ea51";
}
.icon-tools2:before {
    content: "\ea52";
}
.icon-traffic-cone:before {
    content: "\ea53";
}
.icon-tree3:before {
    content: "\ea54";
}
.icon-trophy4:before {
    content: "\ea55";
}
.icon-tv4:before {
    content: "\ea56";
}
.icon-typing:before {
    content: "\ea57";
}
.icon-unread:before {
    content: "\ea58";
}
.icon-untag:before {
    content: "\ea59";
}
.icon-user3:before {
    content: "\ea5a";
}
.icon-users3:before {
    content: "\ea5b";
}
.icon-v-card:before {
    content: "\ea5c";
}
.icon-video2:before {
    content: "\ea5d";
}
.icon-vinyl:before {
    content: "\ea5e";
}
.icon-voicemail2:before {
    content: "\ea5f";
}
.icon-wallet2:before {
    content: "\ea60";
}
.icon-water:before {
    content: "\ea61";
}
.icon-500px-with-circle:before {
    content: "\ea62";
}
.icon-500px3:before {
    content: "\ea63";
}
.icon-basecamp2:before {
    content: "\ea64";
}
.icon-behance3:before {
    content: "\ea65";
}
.icon-creative-cloud:before {
    content: "\ea66";
}
.icon-dropbox3:before {
    content: "\ea67";
}
.icon-evernote:before {
    content: "\ea68";
}
.icon-flattr2:before {
    content: "\ea69";
}
.icon-foursquare3:before {
    content: "\ea6a";
}
.icon-google-drive2:before {
    content: "\ea6b";
}
.icon-google-hangouts:before {
    content: "\ea6c";
}
.icon-grooveshark:before {
    content: "\ea6d";
}
.icon-icloud:before {
    content: "\ea6e";
}
.icon-mixi:before {
    content: "\ea6f";
}
.icon-onedrive2:before {
    content: "\ea70";
}
.icon-paypal3:before {
    content: "\ea71";
}
.icon-picasa:before {
    content: "\ea72";
}
.icon-qq2:before {
    content: "\ea73";
}
.icon-rdio-with-circle:before {
    content: "\ea74";
}
.icon-renren3:before {
    content: "\ea75";
}
.icon-scribd2:before {
    content: "\ea76";
}
.icon-sina-weibo2:before {
    content: "\ea77";
}
.icon-skype-with-circle:before {
    content: "\ea78";
}
.icon-skype3:before {
    content: "\ea79";
}
.icon-slideshare2:before {
    content: "\ea7a";
}
.icon-smashing:before {
    content: "\ea7b";
}
.icon-soundcloud3:before {
    content: "\ea7c";
}
.icon-spotify-with-circle:before {
    content: "\ea7d";
}
.icon-spotify3:before {
    content: "\ea7e";
}
.icon-swarm:before {
    content: "\ea7f";
}
.icon-vine-with-circle:before {
    content: "\ea80";
}
.icon-vine3:before {
    content: "\ea81";
}
.icon-vk-alternitive:before {
    content: "\ea82";
}
.icon-vk-with-circle:before {
    content: "\ea83";
}
.icon-vk3:before {
    content: "\ea84";
}
.icon-xing-with-circle:before {
    content: "\ea85";
}
.icon-xing3:before {
    content: "\ea86";
}
.icon-yelp3:before {
    content: "\ea87";
}
.icon-dribbble-with-circle:before {
    content: "\ea88";
}
.icon-dribbble4:before {
    content: "\ea89";
}
.icon-facebook-with-circle:before {
    content: "\ea8a";
}
.icon-facebook4:before {
    content: "\ea8b";
}
.icon-flickr-with-circle:before {
    content: "\ea8c";
}
.icon-flickr5:before {
    content: "\ea8d";
}
.icon-github-with-circle:before {
    content: "\ea8e";
}
.icon-github3:before {
    content: "\ea8f";
}
.icon-google-with-circle:before {
    content: "\ea90";
}
.icon-google4:before {
    content: "\ea91";
}
.icon-instagram-with-circle:before {
    content: "\ea92";
}
.icon-instagram3:before {
    content: "\ea93";
}
.icon-lastfm-with-circle:before {
    content: "\ea94";
}
.icon-lastfm3:before {
    content: "\ea95";
}
.icon-linkedin-with-circle:before {
    content: "\ea96";
}
.icon-linkedin4:before {
    content: "\ea97";
}
.icon-pinterest-with-circle:before {
    content: "\ea98";
}
.icon-pinterest3:before {
    content: "\ea99";
}
.icon-rdio:before {
    content: "\ea9a";
}
.icon-stumbleupon-with-circle:before {
    content: "\ea9b";
}
.icon-stumbleupon3:before {
    content: "\ea9c";
}
.icon-tumblr-with-circle:before {
    content: "\ea9d";
}
.icon-tumblr4:before {
    content: "\ea9e";
}
.icon-twitter-with-circle:before {
    content: "\ea9f";
}
.icon-twitter4:before {
    content: "\eaa0";
}
.icon-vimeo-with-circle:before {
    content: "\eaa1";
}
.icon-vimeo3:before {
    content: "\eaa2";
}
.icon-youtube-with-circle:before {
    content: "\eaa3";
}
.icon-youtube3:before {
    content: "\eaa4";
}
.icon-box2:before {
    content: "\eaa5";
}
.icon-write:before {
    content: "\eaa6";
}
.icon-clock5:before {
    content: "\eaa7";
}
.icon-reply5:before {
    content: "\eaa8";
}
.icon-reply-all3:before {
    content: "\eaa9";
}
.icon-forward5:before {
    content: "\eaaa";
}
.icon-flag6:before {
    content: "\eaab";
}
.icon-search5:before {
    content: "\eaac";
}
.icon-trash3:before {
    content: "\eaad";
}
.icon-envelope3:before {
    content: "\eaae";
}
.icon-bubble3:before {
    content: "\eaaf";
}
.icon-bubbles5:before {
    content: "\eab0";
}
.icon-user4:before {
    content: "\eab1";
}
.icon-users4:before {
    content: "\eab2";
}
.icon-cloud6:before {
    content: "\eab3";
}
.icon-download6:before {
    content: "\eab4";
}
.icon-upload6:before {
    content: "\eab5";
}
.icon-rain:before {
    content: "\eab6";
}
.icon-sun2:before {
    content: "\eab7";
}
.icon-moon2:before {
    content: "\eab8";
}
.icon-bell4:before {
    content: "\eab9";
}
.icon-folder5:before {
    content: "\eaba";
}
.icon-pin2:before {
    content: "\eabb";
}
.icon-sound2:before {
    content: "\eabc";
}
.icon-microphone2:before {
    content: "\eabd";
}
.icon-camera6:before {
    content: "\eabe";
}
.icon-image5:before {
    content: "\eabf";
}
.icon-cog4:before {
    content: "\eac0";
}
.icon-calendar5:before {
    content: "\eac1";
}
.icon-book5:before {
    content: "\eac2";
}
.icon-map-marker2:before {
    content: "\eac3";
}
.icon-store2:before {
    content: "\eac4";
}
.icon-support2:before {
    content: "\eac5";
}
.icon-tag3:before {
    content: "\eac6";
}
.icon-heart5:before {
    content: "\eac7";
}
.icon-video-camera3:before {
    content: "\eac8";
}
.icon-trophy5:before {
    content: "\eac9";
}
.icon-cart2:before {
    content: "\eaca";
}
.icon-eye4:before {
    content: "\eacb";
}
.icon-cancel2:before {
    content: "\eacc";
}
.icon-chart:before {
    content: "\eacd";
}
.icon-target3:before {
    content: "\eace";
}
.icon-printer3:before {
    content: "\eacf";
}
.icon-location4:before {
    content: "\ead0";
}
.icon-bookmark5:before {
    content: "\ead1";
}
.icon-monitor:before {
    content: "\ead2";
}
.icon-cross3:before {
    content: "\ead3";
}
.icon-plus4:before {
    content: "\ead4";
}
.icon-left:before {
    content: "\ead5";
}
.icon-up:before {
    content: "\ead6";
}
.icon-browser3:before {
    content: "\ead7";
}
.icon-windows3:before {
    content: "\ead8";
}
.icon-switch3:before {
    content: "\ead9";
}
.icon-dashboard3:before {
    content: "\eada";
}
.icon-play4:before {
    content: "\eadb";
}
.icon-fast-forward2:before {
    content: "\eadc";
}
.icon-next3:before {
    content: "\eadd";
}
.icon-refresh4:before {
    content: "\eade";
}
.icon-film3:before {
    content: "\eadf";
}
.icon-home5:before {
    content: "\eae0";
}
.icon-home3:before {
    content: "\eae1";
}
.icon-home22:before {
    content: "\eae2";
}
.icon-home32:before {
    content: "\eae3";
}
.icon-office:before {
    content: "\eae4";
}
.icon-newspaper:before {
    content: "\eae5";
}
.icon-pencil2:before {
    content: "\eae6";
}
.icon-pencil22:before {
    content: "\eae7";
}
.icon-quill:before {
    content: "\eae8";
}
.icon-pen:before {
    content: "\eae9";
}
.icon-blog:before {
    content: "\eaea";
}
.icon-eyedropper2:before {
    content: "\eaeb";
}
.icon-droplet:before {
    content: "\eaec";
}
.icon-paint-format:before {
    content: "\eaed";
}
.icon-image3:before {
    content: "\eaee";
}
.icon-images:before {
    content: "\eaef";
}
.icon-camera3:before {
    content: "\eaf0";
}
.icon-headphones2:before {
    content: "\eaf1";
}
.icon-music2:before {
    content: "\eaf2";
}
.icon-play2:before {
    content: "\eaf3";
}
.icon-film2:before {
    content: "\eaf4";
}
.icon-video-camera2:before {
    content: "\eaf5";
}
.icon-dice:before {
    content: "\eaf6";
}
.icon-pacman:before {
    content: "\eaf7";
}
.icon-spades:before {
    content: "\eaf8";
}
.icon-clubs:before {
    content: "\eaf9";
}
.icon-diamonds:before {
    content: "\eafa";
}
.icon-bullhorn2:before {
    content: "\eafb";
}
.icon-connection:before {
    content: "\eafc";
}
.icon-podcast:before {
    content: "\eafd";
}
.icon-feed2:before {
    content: "\eafe";
}
.icon-mic2:before {
    content: "\eaff";
}
.icon-book3:before {
    content: "\eb00";
}
.icon-books:before {
    content: "\eb01";
}
.icon-library:before {
    content: "\eb02";
}
.icon-file-text2:before {
    content: "\eb03";
}
.icon-profile:before {
    content: "\eb04";
}
.icon-file-empty:before {
    content: "\eb05";
}
.icon-files-empty:before {
    content: "\eb06";
}
.icon-file-text22:before {
    content: "\eb07";
}
.icon-file-picture:before {
    content: "\eb08";
}
.icon-file-music:before {
    content: "\eb09";
}
.icon-file-play:before {
    content: "\eb0a";
}
.icon-file-video:before {
    content: "\eb0b";
}
.icon-file-zip:before {
    content: "\eb0c";
}
.icon-copy2:before {
    content: "\eb0d";
}
.icon-paste2:before {
    content: "\eb0e";
}
.icon-stack:before {
    content: "\eb0f";
}
.icon-folder3:before {
    content: "\eb10";
}
.icon-folder-open2:before {
    content: "\eb11";
}
.icon-folder-plus:before {
    content: "\eb12";
}
.icon-folder-minus:before {
    content: "\eb13";
}
.icon-folder-download:before {
    content: "\eb14";
}
.icon-folder-upload:before {
    content: "\eb15";
}
.icon-price-tag:before {
    content: "\eb16";
}
.icon-price-tags:before {
    content: "\eb17";
}
.icon-barcode2:before {
    content: "\eb18";
}
.icon-qrcode2:before {
    content: "\eb19";
}
.icon-ticket2:before {
    content: "\eb1a";
}
.icon-cart:before {
    content: "\eb1b";
}
.icon-coin-dollar:before {
    content: "\eb1c";
}
.icon-coin-euro:before {
    content: "\eb1d";
}
.icon-coin-pound:before {
    content: "\eb1e";
}
.icon-coin-yen:before {
    content: "\eb1f";
}
.icon-credit-card2:before {
    content: "\eb20";
}
.icon-calculator2:before {
    content: "\eb21";
}
.icon-lifebuoy:before {
    content: "\eb22";
}
.icon-phone3:before {
    content: "\eb23";
}
.icon-phone-hang-up:before {
    content: "\eb24";
}
.icon-address-book:before {
    content: "\eb25";
}
.icon-envelop:before {
    content: "\eb26";
}
.icon-pushpin:before {
    content: "\eb27";
}
.icon-location:before {
    content: "\eb28";
}
.icon-location2:before {
    content: "\eb29";
}
.icon-compass2:before {
    content: "\eb2a";
}
.icon-compass22:before {
    content: "\eb2b";
}
.icon-map3:before {
    content: "\eb2c";
}
.icon-map22:before {
    content: "\eb2d";
}
.icon-history3:before {
    content: "\eb2e";
}
.icon-clock:before {
    content: "\eb2f";
}
.icon-clock2:before {
    content: "\eb30";
}
.icon-alarm2:before {
    content: "\eb31";
}
.icon-bell2:before {
    content: "\eb32";
}
.icon-stopwatch:before {
    content: "\eb33";
}
.icon-calendar2:before {
    content: "\eb34";
}
.icon-printer:before {
    content: "\eb35";
}
.icon-keyboard2:before {
    content: "\eb36";
}
.icon-display:before {
    content: "\eb37";
}
.icon-laptop3:before {
    content: "\eb38";
}
.icon-mobile2:before {
    content: "\eb39";
}
.icon-mobile22:before {
    content: "\eb3a";
}
.icon-tablet3:before {
    content: "\eb4d";
}
.icon-tv3:before {
    content: "\eb4e";
}
.icon-drawer:before {
    content: "\eb4f";
}
.icon-drawer2:before {
    content: "\eb50";
}
.icon-box-add:before {
    content: "\eb51";
}
.icon-box-remove:before {
    content: "\eb52";
}
.icon-download2:before {
    content: "\eb53";
}
.icon-upload2:before {
    content: "\eb54";
}
.icon-floppy-disk:before {
    content: "\eb55";
}
.icon-drive:before {
    content: "\eb56";
}
.icon-database2:before {
    content: "\eb57";
}
.icon-undo3:before {
    content: "\eb58";
}
.icon-redo2:before {
    content: "\eb59";
}
.icon-undo22:before {
    content: "\eb5a";
}
.icon-redo22:before {
    content: "\eb5b";
}
.icon-forward3:before {
    content: "\eb5c";
}
.icon-reply3:before {
    content: "\eb5d";
}
.icon-bubble:before {
    content: "\eb5e";
}
.icon-bubbles:before {
    content: "\eb5f";
}
.icon-bubbles2:before {
    content: "\eb60";
}
.icon-bubble2:before {
    content: "\eb61";
}
.icon-bubbles3:before {
    content: "\eb62";
}
.icon-bubbles4:before {
    content: "\eb63";
}
.icon-user2:before {
    content: "\eb64";
}
.icon-users2:before {
    content: "\eb65";
}
.icon-user-plus2:before {
    content: "\eb66";
}
.icon-user-minus:before {
    content: "\eb67";
}
.icon-user-check:before {
    content: "\eb68";
}
.icon-user-tie:before {
    content: "\eb69";
}
.icon-quotes-left:before {
    content: "\eb6a";
}
.icon-quotes-right:before {
    content: "\eb6b";
}
.icon-hour-glass:before {
    content: "\eb6c";
}
.icon-spinner2:before {
    content: "\eb6d";
}
.icon-spinner22:before {
    content: "\eb6e";
}
.icon-spinner3:before {
    content: "\eb6f";
}
.icon-spinner4:before {
    content: "\eb70";
}
.icon-spinner5:before {
    content: "\eb71";
}
.icon-spinner6:before {
    content: "\eb72";
}
.icon-spinner7:before {
    content: "\eb73";
}
.icon-spinner8:before {
    content: "\eb74";
}
.icon-spinner9:before {
    content: "\eb75";
}
.icon-spinner10:before {
    content: "\eb76";
}
.icon-spinner11:before {
    content: "\eb77";
}
.icon-binoculars2:before {
    content: "\eb78";
}
.icon-search3:before {
    content: "\eb79";
}
.icon-zoom-in:before {
    content: "\eb7a";
}
.icon-zoom-out:before {
    content: "\eb7b";
}
.icon-enlarge:before {
    content: "\eb7c";
}
.icon-shrink:before {
    content: "\eb7d";
}
.icon-enlarge2:before {
    content: "\eb7e";
}
.icon-shrink2:before {
    content: "\eb7f";
}
.icon-key2:before {
    content: "\eb80";
}
.icon-key22:before {
    content: "\eb81";
}
.icon-lock3:before {
    content: "\eb82";
}
.icon-unlocked:before {
    content: "\eb83";
}
.icon-wrench2:before {
    content: "\eb84";
}
.icon-equalizer2:before {
    content: "\eb85";
}
.icon-equalizer22:before {
    content: "\eb86";
}
.icon-cog2:before {
    content: "\eb87";
}
.icon-cogs2:before {
    content: "\eb88";
}
.icon-hammer:before {
    content: "\eb89";
}
.icon-magic-wand:before {
    content: "\eb8a";
}
.icon-aid-kit:before {
    content: "\eb8b";
}
.icon-bug2:before {
    content: "\eb8c";
}
.icon-pie-chart2:before {
    content: "\eb8d";
}
.icon-stats-dots:before {
    content: "\eb8e";
}
.icon-stats-bars:before {
    content: "\eb8f";
}
.icon-stats-bars2:before {
    content: "\eb90";
}
.icon-trophy2:before {
    content: "\eb91";
}
.icon-gift2:before {
    content: "\eb92";
}
.icon-glass2:before {
    content: "\eb93";
}
.icon-glass22:before {
    content: "\eb94";
}
.icon-mug:before {
    content: "\eb95";
}
.icon-spoon-knife:before {
    content: "\eb96";
}
.icon-leaf2:before {
    content: "\eb97";
}
.icon-rocket2:before {
    content: "\eb98";
}
.icon-meter:before {
    content: "\eb99";
}
.icon-meter2:before {
    content: "\eb9a";
}
.icon-hammer2:before {
    content: "\eb9b";
}
.icon-fire2:before {
    content: "\eb9c";
}
.icon-lab:before {
    content: "\eb9d";
}
.icon-magnet2:before {
    content: "\eb9e";
}
.icon-bin:before {
    content: "\eb9f";
}
.icon-bin2:before {
    content: "\eba0";
}
.icon-briefcase2:before {
    content: "\eba1";
}
.icon-airplane:before {
    content: "\eba2";
}
.icon-truck2:before {
    content: "\eba3";
}
.icon-road2:before {
    content: "\eba4";
}
.icon-accessibility2:before {
    content: "\eba5";
}
.icon-target:before {
    content: "\eba6";
}
.icon-shield2:before {
    content: "\eba7";
}
.icon-power2:before {
    content: "\eba8";
}
.icon-switch:before {
    content: "\eba9";
}
.icon-power-cord:before {
    content: "\ebaa";
}
.icon-clipboard2:before {
    content: "\ebab";
}
.icon-list-numbered:before {
    content: "\ebac";
}
.icon-list3:before {
    content: "\ebad";
}
.icon-list22:before {
    content: "\ebae";
}
.icon-tree2:before {
    content: "\ebaf";
}
.icon-menu2:before {
    content: "\ebb0";
}
.icon-menu22:before {
    content: "\ebb1";
}
.icon-menu3:before {
    content: "\ebb2";
}
.icon-menu4:before {
    content: "\ebb3";
}
.icon-cloud3:before {
    content: "\ebb4";
}
.icon-cloud-download2:before {
    content: "\ebb5";
}
.icon-cloud-upload2:before {
    content: "\ebb6";
}
.icon-cloud-check:before {
    content: "\ebb7";
}
.icon-download22:before {
    content: "\ebb8";
}
.icon-upload22:before {
    content: "\ebb9";
}
.icon-download3:before {
    content: "\ebba";
}
.icon-upload3:before {
    content: "\ebbb";
}
.icon-sphere:before {
    content: "\ebbc";
}
.icon-earth:before {
    content: "\ebbd";
}
.icon-link3:before {
    content: "\ebbe";
}
.icon-flag3:before {
    content: "\ebbf";
}
.icon-attachment2:before {
    content: "\ebc0";
}
.icon-eye2:before {
    content: "\ebc1";
}
.icon-eye-plus:before {
    content: "\ebc2";
}
.icon-eye-minus:before {
    content: "\ebc3";
}
.icon-eye-blocked:before {
    content: "\ebc4";
}
.icon-bookmark3:before {
    content: "\ebc5";
}
.icon-bookmarks:before {
    content: "\ebc6";
}
.icon-sun:before {
    content: "\ebc7";
}
.icon-contrast:before {
    content: "\ebc8";
}
.icon-brightness-contrast:before {
    content: "\ebc9";
}
.icon-star-empty:before {
    content: "\ebca";
}
.icon-star-half2:before {
    content: "\ebcb";
}
.icon-star-full:before {
    content: "\ebcc";
}
.icon-heart2:before {
    content: "\ebcd";
}
.icon-heart-broken:before {
    content: "\ebce";
}
.icon-man:before {
    content: "\ebcf";
}
.icon-woman:before {
    content: "\ebd0";
}
.icon-man-woman:before {
    content: "\ebd1";
}
.icon-happy:before {
    content: "\ebd2";
}
.icon-happy2:before {
    content: "\ebd3";
}
.icon-smile:before {
    content: "\ebd4";
}
.icon-smile2:before {
    content: "\ebd5";
}
.icon-tongue:before {
    content: "\ebd6";
}
.icon-tongue2:before {
    content: "\ebd7";
}
.icon-sad:before {
    content: "\ebd8";
}
.icon-sad2:before {
    content: "\ebd9";
}
.icon-wink:before {
    content: "\ebda";
}
.icon-wink2:before {
    content: "\ebdb";
}
.icon-grin:before {
    content: "\ebdc";
}
.icon-grin2:before {
    content: "\ebdd";
}
.icon-cool:before {
    content: "\ebde";
}
.icon-cool2:before {
    content: "\ebdf";
}
.icon-angry:before {
    content: "\ebe0";
}
.icon-angry2:before {
    content: "\ebe1";
}
.icon-evil:before {
    content: "\ebe2";
}
.icon-evil2:before {
    content: "\ebe3";
}
.icon-shocked:before {
    content: "\ebe4";
}
.icon-shocked2:before {
    content: "\ebe5";
}
.icon-baffled:before {
    content: "\ebe6";
}
.icon-baffled2:before {
    content: "\ebe7";
}
.icon-confused:before {
    content: "\ebe8";
}
.icon-confused2:before {
    content: "\ebe9";
}
.icon-neutral:before {
    content: "\ebea";
}
.icon-neutral2:before {
    content: "\ebeb";
}
.icon-hipster:before {
    content: "\ebec";
}
.icon-hipster2:before {
    content: "\ebed";
}
.icon-wondering:before {
    content: "\ebee";
}
.icon-wondering2:before {
    content: "\ebef";
}
.icon-sleepy:before {
    content: "\ebf0";
}
.icon-sleepy2:before {
    content: "\ebf1";
}
.icon-frustrated:before {
    content: "\ebf2";
}
.icon-frustrated2:before {
    content: "\ebf3";
}
.icon-crying:before {
    content: "\ebf4";
}
.icon-crying2:before {
    content: "\ebf5";
}
.icon-point-up:before {
    content: "\ebf6";
}
.icon-point-right:before {
    content: "\ebf7";
}
.icon-point-down:before {
    content: "\ebf8";
}
.icon-point-left:before {
    content: "\ebf9";
}
.icon-warning3:before {
    content: "\ebfa";
}
.icon-notification:before {
    content: "\ebfb";
}
.icon-question2:before {
    content: "\ebfc";
}
.icon-plus2:before {
    content: "\ebfd";
}
.icon-minus2:before {
    content: "\ebfe";
}
.icon-info3:before {
    content: "\ebff";
}
.icon-cancel-circle:before {
    content: "\ec00";
}
.icon-blocked:before {
    content: "\ec01";
}
.icon-cross:before {
    content: "\ec02";
}
.icon-checkmark:before {
    content: "\ec03";
}
.icon-checkmark2:before {
    content: "\ec04";
}
.icon-spell-check:before {
    content: "\ec05";
}
.icon-enter:before {
    content: "\ec06";
}
.icon-exit:before {
    content: "\ec07";
}
.icon-play22:before {
    content: "\ec08";
}
.icon-pause3:before {
    content: "\ec09";
}
.icon-stop3:before {
    content: "\ec0a";
}
.icon-previous:before {
    content: "\ec0b";
}
.icon-next:before {
    content: "\ec0c";
}
.icon-backward2:before {
    content: "\ec0d";
}
.icon-forward22:before {
    content: "\ec0e";
}
.icon-play3:before {
    content: "\ec0f";
}
.icon-pause22:before {
    content: "\ec10";
}
.icon-stop22:before {
    content: "\ec11";
}
.icon-backward22:before {
    content: "\ec12";
}
.icon-forward32:before {
    content: "\ec13";
}
.icon-first:before {
    content: "\ec14";
}
.icon-last:before {
    content: "\ec15";
}
.icon-previous2:before {
    content: "\ec16";
}
.icon-next2:before {
    content: "\ec17";
}
.icon-eject3:before {
    content: "\ec18";
}
.icon-volume-high:before {
    content: "\ec19";
}
.icon-volume-medium:before {
    content: "\ec1a";
}
.icon-volume-low:before {
    content: "\ec1b";
}
.icon-volume-mute:before {
    content: "\ec1c";
}
.icon-volume-mute2:before {
    content: "\ec1d";
}
.icon-volume-increase:before {
    content: "\ec1e";
}
.icon-volume-decrease:before {
    content: "\ec1f";
}
.icon-loop2:before {
    content: "\ec20";
}
.icon-loop22:before {
    content: "\ec21";
}
.icon-infinite:before {
    content: "\ec22";
}
.icon-shuffle2:before {
    content: "\ec23";
}
.icon-arrow-up-left:before {
    content: "\ec24";
}
.icon-arrow-up2:before {
    content: "\ec25";
}
.icon-arrow-up-right:before {
    content: "\ec26";
}
.icon-arrow-right2:before {
    content: "\ec27";
}
.icon-arrow-down-right:before {
    content: "\ec28";
}
.icon-arrow-down2:before {
    content: "\ec29";
}
.icon-arrow-down-left:before {
    content: "\ec2a";
}
.icon-arrow-left2:before {
    content: "\ec2b";
}
.icon-arrow-up-left2:before {
    content: "\ec2c";
}
.icon-arrow-up22:before {
    content: "\ec2d";
}
.icon-arrow-up-right2:before {
    content: "\ec2e";
}
.icon-arrow-right22:before {
    content: "\ec2f";
}
.icon-arrow-down-right2:before {
    content: "\ec30";
}
.icon-arrow-down22:before {
    content: "\ec31";
}
.icon-arrow-down-left2:before {
    content: "\ec32";
}
.icon-arrow-left22:before {
    content: "\ec33";
}
.icon-circle-up:before {
    content: "\ec34";
}
.icon-circle-right:before {
    content: "\ec35";
}
.icon-circle-down:before {
    content: "\ec36";
}
.icon-circle-left:before {
    content: "\ec37";
}
.icon-tab2:before {
    content: "\ec38";
}
.icon-move-up:before {
    content: "\ec39";
}
.icon-move-down:before {
    content: "\ec3a";
}
.icon-sort-alpha-asc2:before {
    content: "\ec3b";
}
.icon-sort-alpha-desc2:before {
    content: "\ec3c";
}
.icon-sort-numeric-asc2:before {
    content: "\ec3d";
}
.icon-sort-numberic-desc:before {
    content: "\ec3e";
}
.icon-sort-amount-asc2:before {
    content: "\ec3f";
}
.icon-sort-amount-desc2:before {
    content: "\ec40";
}
.icon-command:before {
    content: "\ec41";
}
.icon-shift:before {
    content: "\ec42";
}
.icon-ctrl:before {
    content: "\ec43";
}
.icon-opt:before {
    content: "\ec44";
}
.icon-checkbox-checked:before {
    content: "\ec45";
}
.icon-checkbox-unchecked:before {
    content: "\ec46";
}
.icon-radio-checked:before {
    content: "\ec47";
}
.icon-radio-checked2:before {
    content: "\ec48";
}
.icon-radio-unchecked:before {
    content: "\ec49";
}
.icon-crop3:before {
    content: "\ec4a";
}
.icon-make-group:before {
    content: "\ec4b";
}
.icon-ungroup:before {
    content: "\ec4c";
}
.icon-scissors2:before {
    content: "\ec4d";
}
.icon-filter3:before {
    content: "\ec4e";
}
.icon-font2:before {
    content: "\ec4f";
}
.icon-ligature:before {
    content: "\ec50";
}
.icon-ligature2:before {
    content: "\ec51";
}
.icon-text-height2:before {
    content: "\ec52";
}
.icon-text-width2:before {
    content: "\ec53";
}
.icon-font-size:before {
    content: "\ec54";
}
.icon-bold2:before {
    content: "\ec55";
}
.icon-underline2:before {
    content: "\ec56";
}
.icon-italic2:before {
    content: "\ec57";
}
.icon-strikethrough2:before {
    content: "\ec58";
}
.icon-omega:before {
    content: "\ec59";
}
.icon-sigma:before {
    content: "\ec5a";
}
.icon-page-break:before {
    content: "\ec5b";
}
.icon-superscript2:before {
    content: "\ec5c";
}
.icon-subscript2:before {
    content: "\ec5d";
}
.icon-superscript22:before {
    content: "\ec5e";
}
.icon-subscript22:before {
    content: "\ec5f";
}
.icon-text-color:before {
    content: "\ec60";
}
.icon-pagebreak:before {
    content: "\ec61";
}
.icon-clear-formatting:before {
    content: "\ec62";
}
.icon-table2:before {
    content: "\ec63";
}
.icon-table22:before {
    content: "\ec64";
}
.icon-insert-template:before {
    content: "\ec65";
}
.icon-pilcrow:before {
    content: "\ec66";
}
.icon-ltr:before {
    content: "\ec67";
}
.icon-rtl:before {
    content: "\ec68";
}
.icon-section:before {
    content: "\ec69";
}
.icon-paragraph-left:before {
    content: "\ec6a";
}
.icon-paragraph-center:before {
    content: "\ec6b";
}
.icon-paragraph-right:before {
    content: "\ec6c";
}
.icon-paragraph-justify:before {
    content: "\ec6d";
}
.icon-indent-increase:before {
    content: "\ec6e";
}
.icon-indent-decrease:before {
    content: "\ec6f";
}
.icon-share3:before {
    content: "\ec70";
}
.icon-new-tab:before {
    content: "\ec71";
}
.icon-embed:before {
    content: "\ec72";
}
.icon-embed2:before {
    content: "\ec73";
}
.icon-terminal2:before {
    content: "\ec74";
}
.icon-share22:before {
    content: "\ec75";
}
.icon-mail2:before {
    content: "\ec76";
}
.icon-mail22:before {
    content: "\ec77";
}
.icon-mail3:before {
    content: "\ec78";
}
.icon-mail4:before {
    content: "\ec79";
}
.icon-amazon2:before {
    content: "\ec7a";
}
.icon-google2:before {
    content: "\ec7b";
}
.icon-google22:before {
    content: "\ec7c";
}
.icon-google3:before {
    content: "\ec7d";
}
.icon-google-plus2:before {
    content: "\ec7e";
}
.icon-google-plus22:before {
    content: "\ec7f";
}
.icon-google-plus3:before {
    content: "\ec80";
}
.icon-hangouts:before {
    content: "\ec81";
}
.icon-google-drive:before {
    content: "\ec82";
}
.icon-facebook2:before {
    content: "\ec83";
}
.icon-facebook22:before {
    content: "\ec84";
}
.icon-instagram2:before {
    content: "\ec85";
}
.icon-whatsapp2:before {
    content: "\ec86";
}
.icon-spotify2:before {
    content: "\ec87";
}
.icon-telegram:before {
    content: "\ec88";
}
.icon-twitter2:before {
    content: "\ec89";
}
.icon-vine2:before {
    content: "\ec8a";
}
.icon-vk2:before {
    content: "\ec8b";
}
.icon-renren2:before {
    content: "\ec8c";
}
.icon-sina-weibo:before {
    content: "\ec8d";
}
.icon-rss2:before {
    content: "\ec8e";
}
.icon-rss22:before {
    content: "\ec8f";
}
.icon-youtube2:before {
    content: "\ec90";
}
.icon-youtube22:before {
    content: "\ec91";
}
.icon-twitch2:before {
    content: "\ec92";
}
.icon-vimeo2:before {
    content: "\ec93";
}
.icon-vimeo22:before {
    content: "\ec94";
}
.icon-lanyrd:before {
    content: "\ec95";
}
.icon-flickr2:before {
    content: "\ec96";
}
.icon-flickr22:before {
    content: "\ec97";
}
.icon-flickr3:before {
    content: "\ec98";
}
.icon-flickr4:before {
    content: "\ec99";
}
.icon-dribbble2:before {
    content: "\ec9a";
}
.icon-behance2:before {
    content: "\ec9b";
}
.icon-behance22:before {
    content: "\ec9c";
}
.icon-deviantart2:before {
    content: "\ec9d";
}
.icon-500px2:before {
    content: "\ec9e";
}
.icon-steam2:before {
    content: "\ec9f";
}
.icon-steam22:before {
    content: "\eca0";
}
.icon-dropbox2:before {
    content: "\eca1";
}
.icon-onedrive:before {
    content: "\eca2";
}
.icon-github2:before {
    content: "\eca3";
}
.icon-npm:before {
    content: "\eca4";
}
.icon-basecamp:before {
    content: "\eca5";
}
.icon-trello2:before {
    content: "\eca6";
}
.icon-wordpress2:before {
    content: "\eca7";
}
.icon-joomla2:before {
    content: "\eca8";
}
.icon-ello:before {
    content: "\eca9";
}
.icon-blogger:before {
    content: "\ecaa";
}
.icon-blogger2:before {
    content: "\ecab";
}
.icon-tumblr2:before {
    content: "\ecac";
}
.icon-tumblr22:before {
    content: "\ecad";
}
.icon-yahoo2:before {
    content: "\ecae";
}
.icon-yahoo22:before {
    content: "\ecaf";
}
.icon-tux:before {
    content: "\ecb0";
}
.icon-appleinc:before {
    content: "\ecb1";
}
.icon-finder:before {
    content: "\ecb2";
}
.icon-android3:before {
    content: "\ecb3";
}
.icon-windows2:before {
    content: "\ecb4";
}
.icon-windows8:before {
    content: "\ecb5";
}
.icon-soundcloud2:before {
    content: "\ecb6";
}
.icon-soundcloud22:before {
    content: "\ecb7";
}
.icon-skype2:before {
    content: "\ecb8";
}
.icon-reddit2:before {
    content: "\ecb9";
}
.icon-hackernews:before {
    content: "\ecba";
}
.icon-wikipedia:before {
    content: "\ecbb";
}
.icon-linkedin2:before {
    content: "\ecbc";
}
.icon-linkedin22:before {
    content: "\ecbd";
}
.icon-lastfm2:before {
    content: "\ecbe";
}
.icon-lastfm22:before {
    content: "\ecbf";
}
.icon-delicious2:before {
    content: "\ecc0";
}
.icon-stumbleupon2:before {
    content: "\ecc1";
}
.icon-stumbleupon22:before {
    content: "\ecc2";
}
.icon-stackoverflow:before {
    content: "\ecc3";
}
.icon-pinterest2:before {
    content: "\ecc4";
}
.icon-pinterest22:before {
    content: "\ecc5";
}
.icon-xing2:before {
    content: "\ecc6";
}
.icon-xing22:before {
    content: "\ecc7";
}
.icon-flattr:before {
    content: "\ecc8";
}
.icon-foursquare2:before {
    content: "\ecc9";
}
.icon-yelp2:before {
    content: "\ecca";
}
.icon-paypal2:before {
    content: "\eccb";
}
.icon-chrome2:before {
    content: "\eccc";
}
.icon-firefox2:before {
    content: "\eccd";
}
.icon-IE:before {
    content: "\ecce";
}
.icon-edge2:before {
    content: "\eccf";
}
.icon-safari2:before {
    content: "\ecd0";
}
.icon-opera2:before {
    content: "\ecd1";
}
.icon-file-pdf:before {
    content: "\ecd2";
}
.icon-file-openoffice:before {
    content: "\ecd3";
}
.icon-file-word:before {
    content: "\ecd4";
}
.icon-file-excel:before {
    content: "\ecd5";
}
.icon-libreoffice:before {
    content: "\ecd6";
}
.icon-html-five:before {
    content: "\ecd7";
}
.icon-html-five2:before {
    content: "\ecd8";
}
.icon-css32:before {
    content: "\ecd9";
}
.icon-git2:before {
    content: "\ecda";
}
.icon-codepen2:before {
    content: "\ecdb";
}
.icon-svg:before {
    content: "\ecdc";
}
.icon-IcoMoon:before {
    content: "\ecdd";
}

