.title {
  font-weight: bold;

  div {
    color: #535353;
  }
}

:global(footer#footer .cs-footer-nav .footer-nav) {
  .col4 {
    column-count: 4;

    :global(ul li) {
      border: none;
      min-width: 100%;
      
      :global(a) {
        line-height: normal;
      }
    }
    
  }
}
