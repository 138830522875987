.mobile-menu li .home a {
    color: #fff !important;
}
.mobile-menu li .home a:hover {
    color: #fff !important;
}
#search .input-group .input-group-btn .btn-default i {
    color: #fff;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  z-index: 99999;
  background: black;
  opacity: 0.7;
  filter: alpha(opacity=20);
}

.mmPushBody {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
#mobile-menu {
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 99999;
  -webkit-box-shadow: 0 1px 8px #555555;
  -moz-box-shadow: 0 1px 8px #555555;
  -o-box-shadow: 0 1px 8px #555555;
  box-shadow: 0 1px 8px #555555;
  display: none;
  font-family: Helvetica, sans-serif;
  font-size: 15px;
  border-right: 1px solid #f6f6f6;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  -o-box-sizing: content-box;
  box-sizing: content-box;
  line-height: 1.42857;
}

.mobile-menu {
  list-style-type: none;
  margin: 0;
  width: 100%;
  float: left;
  margin-left: 0;
  padding-left: 0;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-menu ul {
  margin-left: 0;
  padding-left: 0;
}
.mobile-menu ul .expand {
  background: #eeeeee;
}
.mobile-menu .mm-search {
  height: 50px;
  background-color: #555555;
  color: #555555;
  padding: 10px 15px;
  box-sizing: border-box;
}
.mobile-menu .input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.mobile-menu .input-group .form-control:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.mobile-menu .input-group .form-control {
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0px;
  font-size:12px;
}
.mobile-menu .input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.mobile-menu .input-group-btn:first-child > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.mobile-menu .input-group-btn > .btn {
  position: relative;
}
.mobile-menu .btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  font-size: 15px;
  line-height: 1.42857;
  border-radius: 0px;
}
.mobile-menu .form-control {
  height: 18px;
  padding: 6px 0;
  font-size: 15px;
  color: #555555;
  background-color: #FFF;
  border-radius: 0px;
  border: 0;
}
.mobile-menu .btn-default {
  height: 30px;
  border: 0;
  padding: 4px 5px;
  background-color: white;
}
.mobile-menu .btn-default:hover {
  background-color: white;
}
.mobile-menu .btn-default:focus {
  background-color: white;
}
.mobile-menu .btn-default:active {
  background-color: white;
}
.mobile-menu .home {
  background-color: #01c7c5;
  color: white;
  height: 43px;
  overflow: hidden;
}
.mobile-menu .home i {
  padding-right: 5px;
}
.mobile-menu .home a.active {
  color: #222222 !important;
}
.mobile-menu .expand {
  right: 0;
  position: absolute;
  border-left: 1px solid #f6f6f6;
  padding: 0 13px;
  color: #01c7c5;
  margin-left: 100px;
  font-size:16px;
}
.mobile-menu .expand:hover {
  cursor: pointer;
}
.mobile-menu li {
  display: block;
  border-top: 1px solid #f6f6f6;
  width: 100%;
  float: left;
  margin-left: 0;
  padding-left: 0;
  position: relative;
}
.mobile-menu li a {
  display: block;
  overflow: hidden;
  white-space: normal;
  color: #222222;
  text-decoration: none;
  padding: 10px;
  padding-left: 15px;
  font-size:12px;
  font-weight:bold;
}
.mobile-menu li a.active {
  color: #fca001;
}
.mobile-menu li li {
  background: #eeeeee;
}
.mobile-menu li li a {
  padding-left: 25px;
}
.mobile-menu li li li {
  background: #dddddd;
}
.mobile-menu li li li a {
  padding-left: 35px;
}
.mobile-menu .social a {
  float: left;
  padding: 0;
  margin: 10px 0 10px 15px;
}
.mobile-menu .social i {
  font-size: 22px;
  color: #01c7c5;
}
.mobile-menu input.simple:focus {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
.mobile-menu [class^="icon-"]:before {
  margin-right: 0;
  margin-left: 0;
}
.mobile-menu [class*=" icon-"]:before {
  margin-right: 0;
  margin-left: 0;
}

.mm-toggle-wrap {
  color: white;
  width: 100%;
  height: 45px;
  background: #555555;
  line-height: 1;
  overflow: hidden;
}

.mm-toggle {
  cursor: pointer;
  font-size: 22px;
  display: none;
  padding: 12px 13px;
  width: auto;
  float: none;
  background: #01c7c5;
  border-right: 1px solid white;
  height: 45px;
}

@media (max-width: 767px) {
  .mm-toggle {
    display: block;
  }
  .mm-toggle .mm-label {
    margin-left: 3px;
  }
}
@media (max-width: 480px) {
  .mm-toggle .mm-label {
    display: none;
  }
}
